export default function author(
  state = {
    fetching: false,
    error: false,
    author: [],
    articles: []
  },
  action
) {
  switch (action.type) {
    case 'AUTHOR_REQUESTED':
      return { ...state, fetching: true }
    case 'AUTHOR_SUCCESS': {
      let authorData, articles

      if (action.data.data.length) {
        articles = action.data.data
        authorData = action.data.data[0].authors[0]
      }

      return {
        ...state,
        fetching: false,
        articles,
        author: authorData
      }
    }
    case 'AUTHOR_ERROR':
      return { ...state, error: true }
    default:
      return state
  }
}
