export default function events(
  state = { countries: [], isFetching: false },
  action
) {
  switch (action.type) {
    case "GET_COUNTRIES":
      return { ...state, isFetching: true }
    case "GET_COUNTRIES_SUCCESS":
      return {
        ...state,
        countries: action.payload.map(({ countryCode, countryName }) => ({
          value: countryCode,
          label: countryName.ar,
          translations: [countryName.en],
        })),
      }
    case "GET_COUNTRIES_ERROR":
      return {
        ...state,
        isError: true,
        isFetching: false,
      }
    default:
      return state
  }
}
