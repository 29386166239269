import { call, put, takeEvery } from "redux-saga/effects"
import base from "./base"
import transformArticle from "../utils/transform-article-data"

function fetchArticlesCall(payload, tabName) {
  const { page = 1, size } = payload

  switch (tabName) {
    case "HISTORY":
      return base.get("/user/history", { size, page })
    case "FAVORITE":
      return base.get("/user/likes", { size, page })
    case "BOOKMARK":
      return base.get("/article/bookmark", { size, page })
    // case "SAVED":
    //   return base.get(("/user/history", { size, page }))
    default:
      return base.get("/user/history", { size, page })
  }
}

function* fetchHistoryArticles({ payload, tabName }) {
  try {
    yield put({ type: "TOOLBAR_ARTICLES_REQUEST_START" })

    const response = yield call(fetchArticlesCall, payload, tabName)
    const { lang, page = 1 } = payload
    const data = response.data.data.map(article =>
      transformArticle({ article, lang })
    )

    yield put({ type: "TOOLBAR_ARTICLES_REQUEST_SUCCESS", data, page, tabName })
  } catch (e) {
    yield put({ type: "TOOLBAR_ARTICLES_REQUEST_ERROR", message: e.message })
  }
}

function* toolbarSaga() {
  yield takeEvery("TOOLBAR_ARTICLES_REQUESTED", fetchHistoryArticles)
}

export default toolbarSaga
