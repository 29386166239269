import { call, put, takeEvery } from "redux-saga/effects"
import base from "./base"
import transformArticle from "../utils/transform-article-data"

export function fetchArticlesCall(payload) {
  const { id, lang, size } = payload

  return base.get('/content/similar', { size, lang, id })
}

function* fetchSimilarArticles({ payload }) {
  try {
    yield put({ type: "SIMILAR_ARTICLES_REQUEST_START" })

    const response = yield call(fetchArticlesCall, payload)
    const { lang } = payload
    const data = response.data.data.map(article =>
      transformArticle({ article, lang })
    )

    yield put({ type: "SIMILAR_ARTICLES_REQUEST_SUCCESS", data })
  } catch (e) {
    yield put({ type: "SIMILAR_ARTICLES_REQUEST_ERROR", message: e.message })
  }
}

function* articleRecommendationsSaga() {
  yield takeEvery("SIMILAR_ARTICLES_REQUESTED", fetchSimilarArticles)
}

export default articleRecommendationsSaga
