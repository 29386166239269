import updateArticles from '../utils/updateArticles'

export default function articleRecommendations(
  state = {
    page: 0,
    fetching: false,
    articleId: null,
    hasMore: true,
    error: false,
    data: []
  },
  action
) {
  switch (action.type) {
    case 'BOOKMARK_ARTICLE_SUCCESS': {
      let itemId = action.payload.value.itemId
      let newValue = !action.payload.value.bookmarkActive

      const newData = updateArticles(state.data, article => ({
        ...article,
        isBookMarked: article.id === itemId ? newValue : article.isBookMarked
      }))

      return { ...state, data: newData }
    }
    case 'ARTICLES_CHANGE_LIKE':
      const newData = updateArticles(state.data, article => ({
        ...article,
        isLiked: article.id === action.id ? action.value : article.isLiked
      }))

      return { ...state, data: newData }
    case 'ARTICLE_RECOMMENDATIONS_REQUEST_START':
      return { ...state, fetching: true }
    case 'ARTICLE_RECOMMENDATIONS_REQUEST_SUCCESS':
      return {
        ...state,
        fetching: false,
        articleId: parseInt(action.articleId),
        hasMore: !!action.data.length,
        page: state.page + 1,
        data: [...state.data, ...action.data],
        gridSize: action.gridSize
      }
    case 'ARTICLE_RECOMMENDATIONS_RESET':
      return {
        page: 0,
        fetching: false,
        articleId: null,
        hasMore: true,
        error: false,
        data: []
      }
    case 'ARTICLE_RECOMMENDATIONS_REQUEST_ERROR':
      return { ...state, error: true }
    default:
      return state
  }
}
