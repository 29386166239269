export default function liveTvSearch(
  state = {
    fetching: false,
    error: false,
    hasMore: false,
    page: 1,
    data: null
  },
  action
) {
  switch (action.type) {
    case 'LIVETV_SEARCH_REQUEST_START':
      return { ...state, fetching: true }
    case 'LIVETV_SEARCH_REQUEST_SUCCESS':
      const { data } = action

      return {
        ...state,
        fetching: false,
        data,
        error: false
      }
    case 'LIVETV_SEARCH_RESET_START':
      return {
        ...state,
        fetching: false,
        error: false,
        data: null
      }
    case 'LIVETV_SEARCH_REQUEST_FAILED':
      return {
        fetching: false,
        error: true,
        page: 1,
        data: null
      }
    default:
      return state
  }
}
