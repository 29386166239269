import { call, put, select, takeLatest, delay } from "redux-saga/effects"

import { sendEvent } from "../utils/sendEvent"

export const getEvents = state => state.events

function sendScrollDownEvents(payload) {
  const itemIds = payload.map(item => item.id)

  return sendEvent("scrollDown", { itemIds: itemIds, page: "homepage" })
}

function sendScrollUpEvents(payload) {
  const itemIds = payload.map(item => item.id)
  return sendEvent("scrollUp", { itemIds: itemIds, page: "homepage" })
}

function* addEvent(action) {
  try {
    const { payload } = action

    yield put({ type: "ADD_EVENT", payload })
  } catch (e) {
    yield put({ type: "USER_FETCH_FAILED", message: e.message })
  }
}

function* submitScrollEvents(action) {
  try {
    while (true) {
      const nonSubmittedScrollEvents = yield select(getEvents)
      const { scrollUpEvents, scrollDownEvents } = nonSubmittedScrollEvents

      yield call(sendScrollDownEvents, scrollDownEvents)
      yield call(sendScrollUpEvents, scrollUpEvents)

      yield put({ type: "CLEAR_ALL_EVENTS" })

      yield delay(5000)
    }
  } catch (e) {
    console.info(e)
  }
}

function* eventSaga() {
  yield takeLatest("ADD_EVENT_REQUEST", addEvent)
  yield takeLatest("SUBMIT_SCROLL_EVENTS_REQUESTED", submitScrollEvents)
}

export default eventSaga
