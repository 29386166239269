import { takeEvery, fork, call } from 'redux-saga/effects'
import apolloClient from './apollo'
import orderBy from 'lodash/orderBy'
import BreakingNews from '../utils/BreakingNews'
import { isBrowser } from '../constants/app'

import { parseISO, format, addSeconds } from 'date-fns'
import DateTimeService from '../utils/common'

const generateMessageList = (el) => {
  const list = []
  let time = parseISO(el.startAt)

  for (let i = 1; i <= el.loop; i++) {
    for (let message of el.messages) {
      let startAt = DateTimeService.formattedDateTime(time)
      let endAt = DateTimeService.formattedDateTime(
        addSeconds(time, el.duration)
      )

      list.push({
        parentId: el.id,
        messageId: message.id,
        text: message.text,
        startAt: startAt,
        endAt: endAt,
        isHighPriority:
          el.template.valueName === '2002' || el.template.valueName === '2003'
      })

      time = addSeconds(time, el.duration)
    }
  }

  return list
}

function* handleNewNews(newData) {
  let news = newData?.data?.breakingNews
  if (news == null) return

  news.messages = news.messages.filter((e) => e.deleted === false)

  BreakingNews.update(news.id, generateMessageList(news))
}

function* subscribeNewNews(params) {
  const channel = yield call(apolloClient.createBreakingNewsEventChannel)
  yield takeEvery(channel, handleNewNews)
}
function* breakingNewsSaga() {
  const isClient = isBrowser()
  let currentNews = isClient ? yield apolloClient.getBreakingNews() : null
  let data = currentNews?.data?.currentBreaking ?? []

  for (let news of data) {
    news.messages = news.messages.filter((e) => e.deleted === false)
  }

  data = data.filter((e) => e.messages.length > 0)

  let messageList = []

  for (let el of data) {
    messageList = messageList.concat(generateMessageList(el))
  }

  messageList = orderBy(
    messageList,
    (o) => {
      return DateTimeService.formatISO(o.startAt)
    },
    ['asc']
  )

  BreakingNews.init(messageList)

  if (isClient) {
    yield fork(subscribeNewNews)
  }
}

export default breakingNewsSaga
