const updateArticles = (data, updateFun) => {
  return data.map(item => {
    if (Array.isArray(item)) {
      return item.map(article => {
        return updateFun(article)
      })
    }
    return {
      ...item,
      data: item.data.map(article => {
        return updateFun(article)
      }),
    }
  })
}

export default updateArticles
