import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  gql,
  split,
  from
} from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import { WebSocketLink } from '@apollo/client/link/ws'
import { onError } from '@apollo/client/link/error'
import { eventChannel } from 'redux-saga'
import fetch from 'cross-fetch'
const ssrMode = typeof window === 'undefined'

const BREAKING_NEWS = `
  id
  loop
  duration
  startAt
  endAt
  messages {
    id
    aired
    protected
    deleted
    text
  }
  template {
    value
    valueName
    label
    vShift
    hShift
    stopPoint
    type
  }
`

const QUERY_BREAKING_NEWS = gql`
  query breakingNews {
    currentBreaking {
      ${BREAKING_NEWS}
    }
  }
`

const SUBSCRIPTION_BREAKING_NEWS = gql`
  subscription breakingNews {
  breakingNews {
    ${BREAKING_NEWS}
  }
}
`

const SERVER_GRAPHQL_URL = process.env.GATSBY_GRAPHQL_URL
const SERVER_GRAPHQL_SUBSCRIPTION_URL =
  process.env.GATSBY_GRAPHQL_SUBSCRIPTION_URL

const httpLink = new HttpLink({
  uri: SERVER_GRAPHQL_URL,
  fetch
})

const wsLink = !ssrMode
  ? new WebSocketLink({
      // if you instantiate in the server, the error will be thrown
      uri: SERVER_GRAPHQL_SUBSCRIPTION_URL,
      options: {
        reconnect: true
      }
    })
  : null

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    )

  if (networkError) console.log(`[Network error]: ${networkError}`)
})

const link = !ssrMode
  ? split(
      // only create the split in the browser
      // split based on operation type
      ({ query }) => {
        const definition = getMainDefinition(query)
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        )
      },
      wsLink,
      httpLink
    )
  : from([errorLink, httpLink])

export const api = new ApolloClient({
  cache: new InMemoryCache(),
  link
})

const createErrorSubscription = (emitter) => {
  return api
    .subscribe({
      query: SUBSCRIPTION_BREAKING_NEWS
    })
    .subscribe({
      next(data) {
        emitter(data)
      },
      error(err) {
        console.error('err', err)
        console.info('Try reconnecting')
        createErrorSubscription(emitter)
      }
    })
}

class Client {
  query(query, variables = {}) {
    return api
      .query({ query, variables, fetchPolicy: 'no-cache', errorPolicy: 'all' })
      .catch(this.catch)
  }

  createBreakingNewsEventChannel() {
    return eventChannel((emitter) => {
      const subscription = createErrorSubscription(emitter)

      return () => {
        subscription.unsubscribe()
      }
    })
  }

  async getBreakingNews() {
    return this.query(QUERY_BREAKING_NEWS)
  }
}

const client = new Client()
export default client
