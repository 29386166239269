export default function toolbar(
  state = {
    toolbarOpen: false,
    fetching: false,
    toolbarData: [],
    page: 0,
    tabName: "",
    hasMore: true,
  },
  action
) {
  switch (action.type) {
    case "TOGGLE_TOOLBAR":
      return { ...state, toolbarOpen: !state.toolbarOpen }
    case "CLOSE_TOOLBAR":
      return { ...state, toolbarOpen: false }
    case "TOOLBAR_ARTICLES_REQUEST_START":
      return { ...state, fetching: true }
    case "TOOLBAR_ARTICLES_REQUEST_SUCCESS":
      const { tabName, page, data } = action

      return {
        ...state,
        fetching: false,
        toolbarData:
          state.tabName !== tabName || page === 1
            ? data
            : [...state.toolbarData, ...data],
        page,
        tabName,
        hasMore: !!data.length,
      }
    case "TOOLBAR_ARTICLES_REQUEST_ERROR":
      return {
        ...state,
        fetching: false,
        toolbarData: [],
        messsage: action.message,
      }
    default:
      return state
  }
}
