import { call, put, takeLatest } from 'redux-saga/effects'
import base from './base'
import transformArticleData from '../utils/transform-article-data'

export function fetchShorthandArticlesCall(payload) {
  const { page, size = 10 } = payload

  return base.get(`/content`, {
    page: page + 1,
    size,
    item_type: 'article',
    article_type: 'shorthand'
  })
}

function* resetShorthandArticlesSaga() {
  try {
    yield put({ type: 'SHORTHAND_ARTICLES_RESET_START' })
  } catch (e) {
    console.log('Error in resetting category', e)
  }
}

function* getShorthandArticles({ payload }) {
  try {
    const { lang = 'ar' } = payload
    yield put({ type: 'SHORTHAND_ARTICLES_REQUEST_START' })

    const response = yield call(fetchShorthandArticlesCall, payload)

    const { data } = response.data

    const shorthandArticles = data.map((article) =>
      transformArticleData({ article, lang })
    )

    yield put({
      type: 'SHORTHAND_ARTICLES_SUCCESS',
      data: shorthandArticles
    })
  } catch (e) {
    yield put({ type: 'SHORTHAND_ARTICLES_ERROR', message: e.message })
  }
}

function* shorthandArticlesSaga() {
  yield takeLatest('FETCH_SHORTHAND_ARTICLES', getShorthandArticles)
  yield takeLatest('RESET_SHORTHAND_ARTICLES', resetShorthandArticlesSaga)
}

export default shorthandArticlesSaga
