export default function auth(
  state = {
    fetching: false,
    isVisible: false,
    fetchingReset: false,
  },
  action
) {
  switch (action.type) {
    case "LOGIN_START":
      return { ...state, fetching: true }
    case "LOGIN_SUCCESS":
      return { ...state, fetching: false }
    case "LOGIN_ERROR":
      return { ...state, fetching: false }
    case "SHOW_LOGIN":
      return { ...state, isVisible: true }
    case "HIDE_LOGIN":
      return { ...state, isVisible: false }
    case "RESET_START":
      return { ...state, fetchingReset: true }
    case "RESET_SUCCESS":
      return { ...state, fetchingReset: false }
    case "RESET_ERROR":
      return { ...state, fetchingReset: false }
    default:
      return state
  }
}
