const truncateString = (text, noOfCharacters) => {
  if (!text) {
    return ''
  }
  const textToTruncate = text.replace(/\n/g, '')
  if (textToTruncate.length <= noOfCharacters) {
    return textToTruncate
  }

  const truncated = textToTruncate.substr(0, noOfCharacters)
  if (textToTruncate.charAt(noOfCharacters) === ' ') {
    return truncated
  }
  return truncated.substr(0, truncated.lastIndexOf(' '))
}

const transformArticleData = ({ article, lang }) => {
  const {
    item_id,
    publishedDate,
    updatedAt,
    createdAt,
    title_ar,
    title_en,
    item_type,
    description_ar,
    description_en,
    trimTitle = false,
    cardSummary = '',
    shortUrl = null,
    views = {}
  } = article

  return {
    id: item_id,
    title: lang === 'en' ? title_en : title_ar,
    body: lang === 'en' ? description_en : description_ar,
    updatedAt,
    createdAt,
    publishedAt: publishedDate,
    type: item_type,
    lang,
    seoDescription: truncateString(
      lang === 'en' ? description_en : description_ar,
      155
    ),
    trimTitle,
    cardSummary,
    shortUrl,
    views
  }
}

module.exports = transformArticleData
