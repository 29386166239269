import { call, put, takeLatest } from 'redux-saga/effects'
import base from './base'

function getBannerCall() {
  return base.get('banners', {})
}

function* getBanner() {
  try {
    yield put({ type: 'BANNER_REQUEST_START' })

    const response = yield call(getBannerCall)

    yield put({ type: 'BANNER_REQUEST_SUCCESS', response })
  } catch (e) {
    yield put({ type: 'BANNER_REQUEST_ERROR', message: e.message })
  }
}

function* bannerSaga() {
  yield takeLatest('BANNER_REQUESTED', getBanner)
}

export default bannerSaga
