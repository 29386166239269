import { call, put, takeEvery } from 'redux-saga/effects'
import base from './base'

function fetchAuthorByName({ payload }) {
  return base.search(null, { author: payload })
}

function* fetchAuthorCallByName(payload) {
  try {
    yield put({ type: 'AUTHOR_REQUESTED' })
    const response = yield call(fetchAuthorByName, payload)
    const { data } = response
    yield put({ type: 'AUTHOR_SUCCESS', data })
  } catch (err) {
    console.log(err)
    yield put({ type: 'AUTHOR_ERROR' })
  }
}

function* authorSaga() {
  yield takeEvery('AUTHOR_REQUEST_BY_NAME', fetchAuthorCallByName)
}

export default authorSaga
