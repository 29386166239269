import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'

import articleSaga from '../sagas/articles'
import searchSaga from '../sagas/search'
import eventSaga from '../sagas/events'
import articleRecommendationsSaga from '../sagas/articleRecommendations'
import categorySaga from '../sagas/categories'
import categoryByIdSaga from '../sagas/categoryById'
import weatherSaga from '../sagas/weather'
import notificationSaga from '../sagas/notifications'
import registerSaga from '../sagas/register'
import latestNewsSaga from '../sagas/latestNews'
import loginSaga from '../sagas/login'
import similarArticlesSaga from '../sagas/similarArticles'
import toolbarSaga from '../sagas/toolbar'
import breakingNewsSaga from '../sagas/breakingNews'
import userSaga from '../sagas/user'
import countriesSaga from '../sagas/countries'
import contextualSearchSaga from '../sagas/contextual-search'
import videosSaga from '../sagas/videosPage'
import livetvRecommendationsSaga from '../sagas/livetvRecommendations'
import authorSaga from '../sagas/author'
import quickLinksSaga from '../sagas/quickLinks'
import bannerSaga from '../sagas/banner'
import adsManagerSaga from '../sagas/adManger'
import liveTvSearchSaga from '../sagas/livetvSearch'
import tagsSaga from '../sagas/tags'
import tagByIdSaga from '../sagas/tagById'
import shorthandArticlesSaga from '../sagas/shorthandArticles'
import voteConfigSaga from '../sagas/voteConfig'

import reducer from '../reducers/index'

export default () => {
  const sagaMiddleware = createSagaMiddleware()

  const initialState = {}

  const composeEnhancers =
    typeof window !== 'undefined'
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
        : compose
      : compose

  const enhancer = composeEnhancers(applyMiddleware(sagaMiddleware))

  const store = createStore(reducer, initialState, enhancer)

  sagaMiddleware.run(quickLinksSaga)
  sagaMiddleware.run(voteConfigSaga)
  sagaMiddleware.run(articleSaga)
  sagaMiddleware.run(searchSaga)
  sagaMiddleware.run(eventSaga)
  sagaMiddleware.run(articleRecommendationsSaga)
  sagaMiddleware.run(categorySaga)
  sagaMiddleware.run(categoryByIdSaga)
  sagaMiddleware.run(weatherSaga)
  sagaMiddleware.run(registerSaga)
  sagaMiddleware.run(notificationSaga)
  sagaMiddleware.run(latestNewsSaga)
  sagaMiddleware.run(loginSaga)
  sagaMiddleware.run(similarArticlesSaga)
  sagaMiddleware.run(toolbarSaga)
  sagaMiddleware.run(breakingNewsSaga)
  sagaMiddleware.run(userSaga)
  sagaMiddleware.run(countriesSaga)
  sagaMiddleware.run(contextualSearchSaga)
  sagaMiddleware.run(videosSaga)
  sagaMiddleware.run(livetvRecommendationsSaga)
  sagaMiddleware.run(authorSaga)
  sagaMiddleware.run(bannerSaga)
  sagaMiddleware.run(adsManagerSaga)
  sagaMiddleware.run(liveTvSearchSaga)
  sagaMiddleware.run(tagsSaga)
  sagaMiddleware.run(tagByIdSaga)
  sagaMiddleware.run(shorthandArticlesSaga)

  return store
}
