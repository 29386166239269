import isEqual from 'lodash/isEqual'
import siteState from '../utils/localState'

export default function categories(
  state = {
    isFetching: false,
    isError: false,
    data: [],
    categoriesName: []
  },
  action
) {
  switch (action.type) {
    case 'CATEGORY_REQUEST_START':
      return { ...state, isFetching: true }
    case 'CATEGORY_REQUEST_SUCCESS': {
      let names = []
      let data = action.response.data.data.map((item) => {
        names.push(item.name.ar)
        return {
          ...item.name,
          id: item.id,
          slug: item.slug,
          showInMenu: item.showInMenu
        }
      })

      let existingCatData = siteState.getState()

      if (!existingCatData.length || !isEqual(existingCatData, names)) {
        siteState.setState(names)
      }

      return {
        ...state,
        data: data,
        categoriesName: names,
        isFetching: false
      }
    }
    case 'CATEGORY_REQUEST_ERROR':
      return {
        ...state,
        isError: true,
        isFetching: false
      }
    default:
      return state
  }
}
