export default function events(
  state = { scrollUpEvents: [], scrollDownEvents: [] },
  action
) {
  switch (action.type) {
    case "ADD_EVENT":
      const { scrollDirection, article, page } = action.payload
      if (scrollDirection === "down") {
        return {
          ...state,
          scrollDownEvents: [
            ...state.scrollDownEvents,
            { id: article.id, page, time: new Date().getTime() },
          ],
        }
      } else if (scrollDirection === "up") {
        return {
          ...state,
          scrollUpEvents: [
            ...state.scrollUpEvents,
            { id: article.id, page, time: new Date().getTime() },
          ],
        }
      }
      break
    case "CLEAR_ALL_EVENTS":
      return { ...state, scrollUpEvents: [], scrollDownEvents: [] }
    default:
      return state
  }
}
