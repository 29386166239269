import { call, put, takeLatest } from 'redux-saga/effects'
import axios from 'axios'
import { toastr } from 'react-redux-toastr'

const API = process.env.GATSBY_API_URL || null

function regiserCall(payload) {
  return axios.post(`${API}/register`, payload)
}

function* register({ payload }) {
  try {
    yield put({ type: 'REGISTER_START' })

    const response = yield call(() => regiserCall(payload))
    const { success, data, errors } = response.data

    if (success) {
      // G360 Event for register
      window.dataLayer.push({
        event: 'asEvent',
        analyticsEventLabel: 'User Register',
        eventCategory: 'User',
        eventAction: 'Register',
        eventLabel: 'Registration successful'
      })

      localStorage.setItem('token', data.token)
      localStorage.setItem('refreshToken', data.refreshToken)
      localStorage.setItem('userType', data.userType)

      yield put({ type: 'REGISTER_SUCCESS', response })
      yield put({ type: 'HIDE_REGISTER' })
      yield put({
        type: 'NEW_NOTIFICATION',
        payload: { text: 'Registration complete. Please check your inbox' }
      })
      yield put({ type: 'USER_REQUEST' })
    } else {
      // G360 Event for register
      window.dataLayer.push({
        event: 'asEvent',
        analyticsEventLabel: 'User Register',
        eventCategory: 'User',
        eventAction: 'Register',
        eventLabel: 'Registration failed'
      })

      toastr.error('Registration failed', errors)
    }
  } catch (e) {
    toastr.error('Registration failed', 'Something went wrong')
    window.dataLayer.push({
      event: 'asEvent',
      analyticsEventLabel: 'User Register',
      eventCategory: 'User',
      eventAction: 'Register',
      eventLabel: 'Registration failed'
    })
  }
}

function* registerSaga() {
  yield takeLatest('REGISTER', register)
}

export default registerSaga
