import { put, takeEvery } from 'redux-saga/effects'
import base from './base'

function* fetchQuickLinks() {
  try {
    yield put({ type: 'QUICK_LINKS_REQUESTED' })
    const response = yield base.getQuickLinks()
    const { data } = response
    yield put({ type: 'QUICK_LINKS_SUCCESS', data })
  } catch (err) {
    console.log(err)
    yield put({ type: 'QUICK_LINKS_ERROR' })
  }
}

function* updateLiveTvMobileView({ payload = false }) {
  try {
    yield put({ type: 'TOGGLE_LIVE_TV_MOBILE', payload })
    // clear live tv search results when closing/opening in mobile
    yield put({ type: 'LIVETV_SEARCH_RESET_START' })
  } catch (err) {
    console.log(err)
  }
}

function* updateAsharqAppPopup({ payload = false }) {
  try {
    yield put({ type: 'TOGGLE_ASHARQ_APP_POPUP', payload })
  } catch (err) {
    console.log(err)
  }
}

function* quickLinksSaga() {
  yield takeEvery('QUICK_LINKS_REQUEST', fetchQuickLinks)
  yield takeEvery('SHOW_HIDE_LIVE_TV_MOBILE', updateLiveTvMobileView)
  yield takeEvery('SHOW_HIDE_ASHARQ_APP_POPUP', updateAsharqAppPopup)
}

export default quickLinksSaga
