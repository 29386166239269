import { call, put, takeLatest } from 'redux-saga/effects'
import { isEmpty } from 'lodash'
import base from './base'

export function searchLiveTvCall(payload) {
  return base.get('/search/omnimonitor', payload)
}

function* searchLiveTv({ payload }) {
  try {
    yield put({ type: 'LIVETV_SEARCH_REQUEST_START' })

    const response = yield call(searchLiveTvCall, payload)

    let data = response.data.data || []

    if (!isEmpty(data)) {
      // sort by date
      data = data.sort(function(a, b) {
        return new Date(b.start_dt) - new Date(a.start_dt)
      })
    }

    yield put({
      type: 'LIVETV_SEARCH_REQUEST_SUCCESS',
      data
    })
  } catch (e) {
    yield put({ type: 'LIVETV_SEARCH_REQUEST_FAILED', message: e.message })
  }
}

function* resetSearch() {
  try {
    yield put({ type: 'LIVETV_SEARCH_RESET_START' })
  } catch (e) {}
}

function* liveTvSearchSaga() {
  yield takeLatest('LIVETV_SEARCH_REQUESTED', searchLiveTv)
  yield takeLatest('LIVETV_SEARCH_RESET', resetSearch)
}

export default liveTvSearchSaga
