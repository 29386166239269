import axios from 'axios'
import Fingerprint2 from 'fingerprintjs2'
import qs from 'qs'

import { CONSTANTS } from '../constants/app'

const API = process.env.GATSBY_API_URL || null
let loginReq = null
let refreshReq = null

const generateFingerprint = () => {
  return new Promise((resolve) => {
    Fingerprint2.getPromise({}).then((components) => {
      let fingerprintId = Fingerprint2.x64hash128(
        components.map((pair) => pair.value).join(),
        31
      )

      resolve(fingerprintId)
    })
  })
}

const testAdminToken = async (token) => {
  try {
    let headers = {}
    headers['Authorization'] = `Bearer ${token}`
    const res = await axios.get(API + '/user', { headers })
    return res?.data?.data?.role === 'admin'
  } catch (ex) {
    console.error(ex.message)
    return false
  }
}

const getPreview = async (token, params) => {
  let headers = {}
  headers['Authorization'] = `Bearer ${token}`
  const res = await axios.get(API + '/content/preview', { headers, params })
  return res
}

const getSubArticlesPreview = async (token, params) => {
  const headers = {
    Authorization: `Bearer ${token}`
  }
  const { lang, spaceId, subArticleIds } = params
  const axiosRequestArr = subArticleIds.map((subArticleId) =>
    axios.get(API + '/content/preview', {
      headers,
      params: {
        id: subArticleId,
        lang,
        spaceId
      }
    })
  )
  const res = await Promise.allSettled(axiosRequestArr)
    .then((response) => response)
    .catch((err) => console.log('error while fetching articles', err))
  return res
}

const getQuickLinks = async () => {
  const res = await get(API + '/quicklinks?size=10&page=1')
  return res
}

const getVoteConfig = async () => {
  const res = await get(API + '/vote/megaphone/')
  return res
}

const getContent = async (id) => {
  const res = await get(`${API}/content?id=${id}`)
  return res
}

const getSubArticleContent = async (id) => {
  const res = await get(`${API}/content?id=${id}&article_type=sub-live`)
  return res
}

const getAdsConfig = async () => {
  const res = await get(API + '/ads?size=20')
  return res
}

const search = async (tokenStr, params) => {
  let token = tokenStr || localStorage.getItem('token')
  let headers = {}
  headers['Authorization'] = `Bearer ${token}`
  const res = await axios.get(API + '/search', { headers, params })
  return res
}

const getFingerprint = () => {
  return new Promise((resolve) => {
    if (window.requestIdleCallback) {
      requestIdleCallback(() => {
        generateFingerprint().then(resolve)
      })
    } else {
      setTimeout(() => {
        generateFingerprint().then(resolve)
      }, 500)
    }
  })
}

const getRequest = async ({ authRequired }) => {
  let token = localStorage.getItem('token')
  let currentRefreshToken = localStorage.getItem('refreshToken')
  let headers = {}

  if ((!token || !currentRefreshToken) && authRequired) {
    if (loginReq === null) {
      loginReq = loginUser()
    }

    await loginReq
    loginReq = null
    token = localStorage.getItem('token')
  }

  if (token && authRequired) {
    headers['Authorization'] = `Bearer ${token}`
  }

  const request = axios.create({
    baseURL: API,
    headers,
    paramsSerializer: (params) => qs.stringify({ ...params })
  })

  request.interceptors.response.use(
    (response) => response,
    (error) => {
      // if blocked at client side (e.g. Adblock)
      if (error && typeof error.response === 'undefined') {
        return error
      }

      if (error.response.status === 401 && error.config.headers.Authorization) {
        if (!refreshReq) {
          refreshReq = refreshToken()
        }

        return refreshReq.then(() => {
          token = localStorage.getItem('token')
          error.config.headers['Authorization'] = 'Bearer ' + token
          refreshReq = null

          return axios.request(error.config)
        })
      }

      return error
    }
  )

  return request
}

const loginUser = async () => {
  if (!localStorage.getItem('fingerprint')) {
    const newFingerprint = await getFingerprint()
    localStorage.setItem('fingerprint', newFingerprint)
  }

  const fingerprint = localStorage.getItem('fingerprint')

  return axios.post(`${API}/login`, { fingerprint }).then((response) => {
    const { token, refreshToken, userType } = response.data.data
    localStorage.setItem('token', token)
    localStorage.setItem('refreshToken', refreshToken)
    localStorage.setItem('userType', userType)
  })
}

const refreshToken = async () => {
  let currentRefreshToken = localStorage.getItem('refreshToken')
  return new Promise((resolve) => {
    axios({
      method: 'post',
      baseURL: API,
      url: '/access/refreshToken',
      data: { refreshToken: currentRefreshToken }
    })
      .then((response) => {
        const { token, refreshToken } = response.data.data
        localStorage.setItem('token', token)
        localStorage.setItem('refreshToken', refreshToken)
        resolve()
      })
      .catch((err) => {
        if (err.response.status === 401) {
          loginUser().then(resolve)
        }
      })
  })
}

const get = async (url, params = {}) => {
  const { authRequired = true, ...restParams } = params
  const request = await getRequest({ authRequired })

  return new Promise((resolve, reject) => {
    request({
      url,
      method: 'get',
      params: restParams
    })
      .then(resolve)
      .catch(reject)
  })
}

const post = async (url, data = {}) => {
  const { authRequired = true, ...restData } = data
  const request = await getRequest({ authRequired })

  return new Promise((resolve, reject) => {
    request({
      url,
      method: 'post',
      data: restData
    })
      .then(resolve)
      .catch(reject)
  })
}

const put = async (url, data = {}) => {
  const { authRequired = true, ...restData } = data
  const request = await getRequest({ authRequired })

  return new Promise((resolve, reject) => {
    request({
      url,
      method: 'put',
      data: restData
    })
      .then(resolve)
      .catch(reject)
  })
}

const destroy = async (url, data = {}) => {
  const { authRequired = true, ...restData } = data
  const request = await getRequest({ authRequired })

  return new Promise((resolve, reject) => {
    request({
      url,
      method: 'delete',
      data: restData
    })
      .then(resolve)
      .catch(reject)
  })
}

const patch = async (url, data = {}) => {
  const { authRequired = true, ...restData } = data
  const request = await getRequest({ authRequired })

  return new Promise((resolve, reject) => {
    request({
      url,
      method: 'patch',
      data: restData
    })
      .then(resolve)
      .catch(reject)
  })
}

const handlePromise = (promise) => {
  return promise.then(
    (v) => {
      return { status: 'fulfilled', value: v }
    },
    (error) => {
      return { status: 'rejected', reason: error }
    }
  )
}

const getSportsArticles = async () => {
  let config = {
    method: 'get',
    url: CONSTANTS.SPORTS_ARTICLES_API,
    headers: {
      Authorization: CONSTANTS.SPORTS_API_AUTHORISATION
    }
  }

  return new Promise((resolve, reject) => {
    axios(config)
      .then((res) => {
        if (res && res.data && res.data.data) {
          return resolve(res)
        } else {
          resolve({
            data: {
              data: []
            }
          })
        }
      })
      .catch((err) => {
        console.error('Error white fetching sports article', err)
        resolve({
          data: {
            data: []
          }
        })
      })
  })
}

export default {
  testAdminToken,
  get,
  post,
  put,
  patch,
  destroy,
  getPreview,
  search,
  getQuickLinks,
  getSubArticlesPreview,
  getContent,
  getAdsConfig,
  getSubArticleContent,
  getVoteConfig,
  getSportsArticles
}
