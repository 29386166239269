export default function adsManager(
  state = {
    fetching: false,
    fetched: false,
    error: false,
    accountId: null,
    list: []
  },
  action
) {
  switch (action.type) {
    case 'ADS_CONFIG_REQUESTED':
      return { ...state, fetching: true }
    case 'ADS_CONFIG_SUCCESS': {
      let list, accountId

      if (action.data.data.length) {
        list = action.data.data
        accountId =
          typeof action.data.account === 'string'
            ? action.data.account
            : action.data.account.gptPath
      }

      return {
        ...state,
        fetching: false,
        fetched: true,
        list,
        accountId
      }
    }
    case 'ADS_CONFIG_ERROR':
      return { ...state, error: true, fetched: true, list: [], accountId: null }
    default:
      return state
  }
}
