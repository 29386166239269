import { throttle } from 'lodash'
import testMobile from '../utils/testMobile'

export const breakpoints = {
  xl: 1450,
  l: 900,
  m: 500
}

export const checkLandscape = throttle(() => {
  if (typeof window !== 'undefined') {
    const isMobile =
      testMobile() ||
      window.matchMedia(
        'screen and (min-width: 414px) and (max-width: 896px) and (orientation: landscape)'
      ).matches ||
      window.matchMedia(
        'screen and (max-width: 767px) and (orientation: landscape)'
      ).matches ||
      window.matchMedia(
        'screen and (max-width: 896px) and (orientation: landscape)'
      ).matches ||
      window.matchMedia(
        'screen and (max-width: 812px) and (orientation: landscape)'
      ).matches ||
      window.matchMedia(
        'screen and (max-width: 926px) and (orientation: landscape)'
      ).matches
    return isMobile
  }
  return false
}, 200)

export const isBrowser = () => {
  return typeof window === 'object'
}

export const CONSTANTS = {
  SPORTS_BASE_URL:
    process.env.GATSBY_SPORTS_BASE_URL || 'https://sports.asharq.com',
  SPORTS_ARTICLES_API:
    process.env.GATSBY_SPORTS_FEATURED_ARTICLES_API ||
    'https://sportsapi.asharq.com/api/v1/articles?page=1&limit=4&type[0]=news&type[1]=photo',
  SPORTS_API_AUTHORISATION: process.env.GATSBY_SPORTS_API_AUTHORISATION || "EH}jhk/K.uj@sPS!w7L}l){K_4:uHLw?Qmg5NLpe'~CNSa;AqI8A]&4}xQS!4'q"
}
