let user =
  typeof window !== "undefined" ? localStorage.getItem("user") : undefined
user = user ? JSON.parse(user) : {}

export default function users(
  state = { user: user, isLoading: false },
  action
) {
  switch (action.type) {
    case "USER_REQUEST_START":
      return { ...state, isLoading: true }
    case "USER_REQUEST_SUCCESS":
      return {
        ...state,
        user: action.payload ? action.payload : state.user,
        isLoading: false,
      }
    case "USER_REQUEST_ERROR":
      return { ...state, isLoading: false }
    default:
      return state
  }
}
