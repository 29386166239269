const articleCardReshuffle = (data, type = false) => {
  const temp4 = data.filter(
    (item) => item.templateNumber && Number(item.templateNumber) === 4
  )
  const temp3 = data.filter(
    (item) => item.templateNumber && Number(item.templateNumber) === 3
  )
  const temp2 = data.filter(
    (item) =>
      (item.item_type && item.item_type === 'headline') ||
      item.templateNumber === NaN ||
      (item.templateNumber && Number(item.templateNumber) === 2)
  )
  const temp1 = data.filter(
    (item) => item.templateNumber && Number(item.templateNumber) === 1
  )
  // filter rest items
  const remains = data.filter(
    (item) =>
      Number(item.templateNumber) !== 4 &&
      Number(item.templateNumber) !== 3 &&
      Number(item.templateNumber) !== 1 &&
      ((item.item_type && item.item_type !== 'headline') ||
        (item.templateNumber && Number(item.templateNumber) !== 2))
  )

  let articles = []
  let items = 0
  let switchRow = false
  let rowItems = []

  if (type) {
    switchRow = true
  }

  while (items < data.length) {
    let itm1, itm2, itm3, itm4

    if (switchRow) {
      // columns [1/4, 1/4, 1/3, 1/2]
      itm1 = temp2.shift() || temp1.shift() || remains.shift()
      itm2 = temp2.shift() || temp1.shift() || remains.shift()
      itm3 = temp4.shift() || temp1.shift() || temp2.shift() || remains.shift()
      itm4 =
        temp3.shift() ||
        temp4.shift() ||
        temp1.shift() ||
        temp2.shift() ||
        remains.shift()
      switchRow = false
    } else {
      // columns [1/2, 1/3, 1/4, 1/4]
      itm1 =
        temp3.shift() ||
        temp4.shift() ||
        temp1.shift() ||
        temp2.shift() ||
        remains.shift()
      itm2 = temp4.shift() || temp1.shift() || temp2.shift() || remains.shift()
      itm3 = temp2.shift() || temp1.shift() || remains.shift()
      itm4 = temp2.shift() || temp1.shift() || remains.shift()
      switchRow = true
    }
    rowItems = [itm1, itm2, itm3, itm4].filter((item) => item !== undefined)
    articles = articles.concat(rowItems)
    items = items + rowItems.length
  }

  return articles
}

export default articleCardReshuffle
