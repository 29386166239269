import { call, put, takeLatest } from "redux-saga/effects"
import base from "./base"

function getCountriesCall() {
  return base.get("/country/details")
}

function* getCountries() {
  try {
    const response = yield call(getCountriesCall)
    const data = response.data.data

    yield put({ type: "GET_COUNTRIES_SUCCESS", payload: data })
  } catch (e) {
    yield put({ type: "GET_COUNTRIES_ERROR" })
  }
}

function* categorySaga() {
  yield takeLatest("GET_COUNTRIES", getCountries)
}

export default categorySaga
