import { call, put, takeLatest } from 'redux-saga/effects'
import base from './base'

function getTagsCall() {
  return base.get('tags', {})
}

function* getTags() {
  try {
    yield put({ type: 'TAGS_REQUEST_START' })

    const response = yield call(getTagsCall)

    yield put({ type: 'TAGS_REQUEST_SUCCESS', response })
  } catch (e) {
    yield put({ type: 'TAGS_REQUEST_ERROR', message: e.message })
  }
}

function* tagsSaga() {
  yield takeLatest('TAGS_REQUESTED', getTags)
}

export default tagsSaga
