import { put, takeEvery } from 'redux-saga/effects'
import base from './base'

function* fetchVoteConfig() {
  try {
    yield put({ type: 'VOTE_MEGAPHONE_REQUESTED' })
    const response = yield base.getVoteConfig()
    const { data } = response
    yield put({ type: 'VOTE_MEGAPHONE_SUCCESS', data })
  } catch (err) {
    console.log(err)
    yield put({ type: 'VOTE_MEGAPHONE_ERROR' })
  }
}

function* voteConfigSaga() {
  yield takeEvery('VOTE_MEGAPHONE_REQUEST', fetchVoteConfig)
}

export default voteConfigSaga
