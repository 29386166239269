import { call, put, takeLatest } from 'redux-saga/effects'
import { omit, isEmpty, uniqBy } from 'lodash'
import base from './base'
import transformArticle from '../utils/transform-article-data'
import transformHeadline from '../utils/transform-headline'
import { searchLiveTvCall } from './livetvSearch'

export function searchArticlesCall(payload, size = null) {
  const { page, searchTerm, searchCategory, searchAuthor, lang, ...rest } =
    payload
  const params = { page: page + 1, ...rest }

  if (searchCategory) {
    params.category = searchCategory
  }

  if (searchAuthor) {
    params.author = searchAuthor
  }

  if (searchTerm) {
    params.q = searchTerm
  }

  const tmpUrl = localStorage.getItem('searchDemo')

  if (size) {
    params.size = size
  }

  return base.get(tmpUrl ? '/search' : '/search/contextual', params)
}

async function searchArticleDataFirstView(
  payload,
  headlinesPayload,
  size = null
) {
  const { lang } = payload
  payload.item_type = 'article'

  let videoPayload = { ...payload }
  videoPayload.item_type = 'video'

  const requests = [
    searchArticlesCall(payload, size),
    searchArticlesCall(headlinesPayload, size),
    searchArticlesCall(videoPayload, size),
    searchLiveTvCall(
      {
        q: payload.searchTerm,
        limit: 3
      },
      size
    )
  ]

  return await Promise.all(requests).then(
    ([articleRes, headlineRes, videoRes, livetvRes]) => {
      let articleData = []
      let headlinesData = []
      let videoData = []
      let liveTvSearchData = []

      if (articleRes && articleRes.data && !isEmpty(articleRes.data.data)) {
        articleData = articleRes.data.data.map((article) => {
          if (article.associatedArticle) {
            article = article.associatedArticle
          }

          return transformArticle({ article, lang })
        })
      }

      if (headlineRes && headlineRes.data && !isEmpty(headlineRes.data.data)) {
        headlinesData = headlineRes.data.data.map((headlineArticle) => {
          return transformHeadline({ article: headlineArticle, lang })
        })
      }

      if (videoRes && videoRes.data && !isEmpty(videoRes.data.data)) {
        videoData = videoRes.data.data.map((videoArticle) => {
          if (videoArticle.associatedArticle) {
            videoArticle = videoArticle.associatedArticle
          }

          return transformArticle({ article: videoArticle, lang })
        })

        videoData = uniqBy(videoData, 'id')
      }

      if (livetvRes && livetvRes.data && !isEmpty(livetvRes.data.data)) {
        let liveTvData = livetvRes.data.data || []

        if (!isEmpty(liveTvData)) {
          // sort by date
          liveTvSearchData = liveTvData.sort(function (a, b) {
            return new Date(b.start_dt) - new Date(a.start_dt)
          })
        }
      }

      return {
        articles: articleData,
        headlines: headlinesData,
        videos: videoData,
        liveTvSearch: liveTvSearchData
      }
    }
  )
}

function* searchArticles({ payload }) {
  try {
    yield put({ type: 'CONTEXTUAL_SEARCH_REQUEST_START' })
    const { lang, searchCategory, page, searchTerm, searchAuthor } = payload
    let data = []

    let newPayload = omit(payload, 'isNewsType')
    // for new filter type, fetch both article and headline data
    let headlinesPayload = omit(payload, 'isNewsType')
    headlinesPayload.item_type = 'headline'

    if (payload && payload.item_type === 'all') {
      newPayload.item_type = 'article'
      payload.item_type = 'article'

      data = yield call(
        searchArticleDataFirstView,
        newPayload,
        headlinesPayload,
        3
      )

      // filter duplicate articles if any
      data.articles = uniqBy(data.articles, 'id')
      data.headlines = uniqBy(data.headlines, 'id')
      data.videos = uniqBy(data.videos, 'id')
      data.liveTvSearch = uniqBy(data.liveTvSearch, 'id')

      const searchLength =
        data.articles.length +
        data.headlines.length +
        data.videos.length +
        data.liveTvSearch.length

      if (data && searchLength > 0 && page === 0) {
        window.dataLayer.push({
          event: 'search_data',
          analyticsEventLabel: 'Search',
          search_term: searchTerm || searchAuthor,
          search_results: searchLength
        })
      }
    } else {
      const response = yield call(searchArticlesCall, newPayload)
      data = {
        articles: response.data.data
          .filter((ar) => ar.article_type !== 'sub-live')
          .map((article) => {
            if (article.associatedArticle) {
              article = article.associatedArticle
            }

            return transformArticle({ article, lang })
          })
      }

      // filter duplicate articles if any
      data.articles = uniqBy(data.articles, 'id')

      if (data && data.articles.length > 0 && page === 0) {
        window.dataLayer.push({
          event: 'search_data',
          analyticsEventLabel: 'Search',
          search_term: searchTerm || searchAuthor,
          search_results: data.articles.length
        })
      }
    }

    yield put({
      type: 'CONTEXTUAL_SEARCH_REQUEST_SUCCESS',
      data,
      searchCategory,
      page
    })
  } catch (e) {
    yield put({ type: 'CONTEXTUAL_SEARCH_REQUEST_FAILED', message: e.message })
  }
}

function* resetSearch() {
  try {
    yield put({ type: 'CONTEXTUAL_SEARCH_RESET_START' })
  } catch (e) {}
}

function* articleSaga() {
  yield takeLatest('CONTEXTUAL_SEARCH_REQUESTED', searchArticles)
  yield takeLatest('CONTEXTUAL_SEARCH_RESET', resetSearch)
}

export default articleSaga
