import { parseISO, format, isAfter } from 'date-fns'
import DateTimeService from './common'
import orderBy from 'lodash/orderBy'

class BreakingNewsBase {
  constructor() {
    this.messageList = []
  }

  init(list) {
    this.messageList = list
  }

  update(newId, list) {
    let newList = this.messageList.filter((e) => e.parentId !== newId)
    newList = newList.concat(list)
    this.messageList = orderBy(
      newList,
      (o) => {
        return format(parseISO(o.startAt), 'yyyyMMddHHmmss')
      },
      ['asc']
    )
  }

  endMessage(id) {
    this.messageList.forEach((item) => {
      if (item.parentId === id) {
        item.endAt = DateTimeService.formattedDateTime(item.startAt)
        item.duration = 0
      }
      return item
    })
  }

  getMessage(now) {
    let i = 0
    while (
      i < this.messageList.length &&
      isAfter(now, parseISO(this.messageList[i].endAt))
    ) {
      i++
    }

    this.messageList = this.messageList.slice(i)

    if (this.messageList.length === 0) {
      return null
    }

    if (isAfter(now, parseISO(this.messageList[0].startAt))) {
      return this.messageList[0]
    }

    return null
  }
}

const BreakingNews = new BreakingNewsBase()

export default BreakingNews
