import ar from 'date-fns/locale/ar'
import isEmpty from 'lodash/isEmpty'
import {
  isValid,
  parseISO,
  formatISO,
  formatDuration,
  secondsToMinutes
} from 'date-fns'
import { formatInTimeZone, utcToZonedTime, format } from 'date-fns-tz'

export default class DateTimeService {
  timezone = 'Asia/Dubai'

  /**
   *
   * @param {String} dateTime date String
   * @returns String in localized format =>
   *
   * Input Str:  2021-07-14T19:34:58Z
   * Output Str: 14 يوليو 2021 23:34 || formatter based
   * equivalent: moment (isoDateStr).locale(`ar-ly`).format('LLL')
   */
  static formatInTimeZoneDate(
    dateTime,
    dateFormat = 'dd MMMM yyyy HH:mm',
    lang = 'ar'
  ) {
    let localeSetting = lang === 'ar' ? { locale: ar } : null

    if (dateTime && isValid(parseISO(dateTime))) {
      return formatInTimeZone(
        parseISO(dateTime),
        this.timezone,
        dateFormat,
        localeSetting
      )
    }
    return dateTime
  }
  /**
   *
   * @param {String} dateTime date String
   * @returns String in local format with arabic local
   *
   * Input Str:  2021-07-14T19:34:58Z
   * Output Str: 14 يوليو 2021 23:34 || formatter based
   * equivalent: moment (isoDateStr).locale(`ar-ly`).format('LLL')
   */
  static formatInLocalTime(
    dateTime,
    dateFormat = 'dd MMMM yyyy HH:mm',
    lang = 'ar'
  ) {
    let localeSetting = lang === 'ar' ? { locale: ar } : null
    if (dateTime && isValid(parseISO(dateTime))) {
      return format(parseISO(dateTime), dateFormat, localeSetting)
    }
    return dateTime
  }

  /**
   *
   * @param {Object} dateTime date object
   * @returns String in format
   *
   * Input Str:  2021-07-14T19:34:58Z
   * Output Str: 2021-07-14T23:34:58+04:00
   * equivalent: moment ().format()
   */
  static formattedDateTime(dateTime = new Date()) {
    if (isValid(dateTime)) {
      return formatISO(utcToZonedTime(dateTime, this.timezone))
    }
    return dateTime
  }

  /**
   *
   * @param {String} dateTime date string
   * @returns String in format
   *
   * Input Str:  2021-07-14T19:34:58Z
   * Output Str: 2021-07-14T23:34:58+04:00
   * equivalent: moment ().format()
   */
  static formatISO(dateTime, pattern = 'yyyyMMddHHmmss', lang = 'ar') {
    let localeSetting = lang === 'ar' ? { locale: ar } : null

    if (dateTime && isValid(parseISO(dateTime))) {
      return format(parseISO(dateTime), pattern, localeSetting)
    }
    return dateTime
  }
  /**
   *
   * @param {String} time in seconds
   * @returns String humanized time
   *
   * Input Str:  148.356542
   * Output Str: دقيقتان
   * equivalent: moment.duration("148.356542", "seconds").locale("ar-ly").humanize()
   */
  static formatDurationWithLocale(durationValue) {
    return formatDuration(
      { minutes: secondsToMinutes(durationValue) },
      { locale: ar }
    )
  }

  static parseISOIfValid(date) {
    if (date && isValid(parseISO(date))) {
      return parseISO(date)
    }

    return date
  }
}

export const getCategoryUrl = (categoryName, categories) => {
  const { data = [] } = categories
  let categorySlug = ''

  const category = data.find((categoryData) => {
    if (categoryData.ar === categoryName) {
      return true
    }
  })

  if (category) {
    categorySlug = category.slug
  }

  return categorySlug
}

export const numFormatter = (num) => {
  if (num > 999 && num < 1000000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K' // convert to K for number from > 1000 < 1 million
  } else if (num > 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M' // convert to M for number from > 1 million
  } else if (num < 900) {
    return num // if value < 1000, nothing to do
  }
}

export const getBrightcoveVideoId = (videoSrc) => {
  if (videoSrc) {
    const matchArr = videoSrc.match(/videoId=([^&]+)/)
    if (!isEmpty(matchArr)) {
      return matchArr[1] || null
    }
  }

  return null
}
