import { put, takeEvery } from 'redux-saga/effects'
import base from './base'

function* fetchAdsConfig() {
  try {
    yield put({ type: 'ADS_CONFIG_REQUESTED' })
    const response = yield base.getAdsConfig()

    if (response && response.data) {
      yield put({ type: 'ADS_CONFIG_SUCCESS', data: response.data })
    } else {
      yield put({ type: 'ADS_CONFIG_ERROR', err: response.message })
    }
  } catch (err) {
    yield put({ type: 'ADS_CONFIG_ERROR', err })
  }
}

function* adsManagerSaga() {
  yield takeEvery('ADS_CONFIG_REQUEST', fetchAdsConfig)
}

export default adsManagerSaga
