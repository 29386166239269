export default function banner(
  state = { isFetching: false, isError: false, data: {} },
  action
) {
  switch (action.type) {
    case 'BANNER_REQUEST_START':
      return { ...state, isFetching: true }
    case 'BANNER_REQUEST_SUCCESS':
      return {
        ...state,
        data: action.response.data.data,
        isFetching: false,
        isError: false
      }
    case 'BANNER_REQUEST_ERROR':
      return {
        ...state,
        isError: true,
        isFetching: false
      }
    default:
      return state
  }
}
