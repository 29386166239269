export default function quickLinks(
  state = {
    fetching: false,
    fetched: false,
    error: false,
    quickLinks: [],
    showLiveTvMobileInGrid: false,
    isAsharqAppPopupVisible: false
  },
  action
) {
  switch (action.type) {
    case 'QUICK_LINKS_REQUESTED':
      return { ...state, fetching: true }
    case 'QUICK_LINKS_SUCCESS': {
      let quickLinks = []

      if (action.data && action.data.data.length) {
        quickLinks = action.data.data
      }

      return {
        ...state,
        fetching: false,
        fetched: true,
        quickLinks
      }
    }
    case 'QUICK_LINKS_ERROR':
      return { ...state, error: true }
    case 'TOGGLE_LIVE_TV_MOBILE':
      return { ...state, showLiveTvMobileInGrid: action.payload }
    case 'TOGGLE_ASHARQ_APP_POPUP':
      return { ...state, isAsharqAppPopupVisible: action.payload }
    default:
      return state
  }
}
