import { call, put, takeLatest } from 'redux-saga/effects'
import base from './base'

function getCategoriesCall() {
  return base.get('categories', { size: 100 })
}

function* getCategories() {
  try {
    yield put({ type: 'CATEGORY_REQUEST_START' })

    const response = yield call(getCategoriesCall)

    yield put({ type: 'CATEGORY_REQUEST_SUCCESS', response })
  } catch (e) {
    yield put({ type: 'CATEGORY_REQUEST_ERROR', message: e.message })
  }
}

function* categorySaga() {
  yield takeLatest('CATEGORY_REQUESTED', getCategories)
}

export default categorySaga
