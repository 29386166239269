import updateArticles from '../utils/updateArticles'
import DateTimeService from '../utils/common'

export default function articles(
  state = {
    page: 0,
    fetching: false,
    hasMore: true,
    error: false,
    data: [],
    lastUpdate: null,
    firstRequest: true,
    lang: ''
  },
  action
) {
  switch (action.type) {
    case 'BOOKMARK_ARTICLE_SUCCESS': {
      let itemId = action.payload.value.itemId
      let newValue = !action.payload.value.bookmarkActive

      const newData = updateArticles(state.data, (article) => ({
        ...article,
        isBookMarked: article.id === itemId ? newValue : article.isBookMarked
      }))

      return {
        ...state,
        data: newData
      }
    }
    case 'ARTICLES_CHANGE_LIKE':
      const newData = updateArticles(state.data, (article) => ({
        ...article,
        isLiked: article.id === action.id ? action.value : article.isLiked
      }))

      return { ...state, data: newData }
    case 'ARTICLES_REQUEST_START':
      return { ...state, fetching: true }
    case 'ARTICLES_REQUEST_SUCCESS':
      const data = action.data
      // when fetching recommendations for first row, always inset a latestNews card
      if (data.length && action.page === 0 && action.gridSize !== 's') {
        if (data[0].isCategory) {
          data[0].data.push({ latestNews: true })
        } else {
          data[0].push({ latestNews: true })
        }
      }

      return {
        ...state,
        fetching: false,
        hasMore: !!data.length,
        page: state.page + 1,
        data: action.page === 0 ? data : [...state.data, ...data],
        lastUpdate: DateTimeService.formattedDateTime(),
        firstRequest: false,
        gridSize: action.gridSize,
        lang: action.lang
      }
    case 'ARTICLES_REQUEST':
      return {
        page: 0,
        fetching: false,
        hasMore: true,
        error: false,
        data: [],
        firstRequest: false
      }
    case 'ARTICLES_REQUEST_ERROR':
      return { ...state, error: true }
    case 'ARTICLES_REARRANGE_DELETE':
      const { articleId } = action.payload

      const newDataArr = []

      const copyData = state.data.map((e) => e)

      for (let i = 0; i < copyData.length; i++) {
        let row = copyData[i]
        const newRowData = row.data.filter((data) => data.id !== articleId)
        row.data = newRowData
        newDataArr.push(row)
      }

      return { ...state, data: newDataArr }
    case 'ARTICLES_MOVE_BOTTOM': {
      const { dragArticleId, articleData } = action.payload

      const newArr = []

      const copyState = state.data.map((e) => e)

      for (let i = 0; i < copyState.length; i++) {
        const row = copyState[i]

        let newRowData = row.data.filter((e) => e.id !== dragArticleId)
        if (i === 1) {
          newRowData = newRowData.concat([articleData])
        }
        row.data = newRowData
        newArr.push(row)
      }

      if (copyState.length === 1) {
        newArr.push({
          data: [articleData],
          isCategory: true,
          isFirst: false,
          isLast: true,
          isLinkable: true,
          isTrending: false,
          label: undefined,
          linkTo: '/ar/search/?category=undefined'
        })
      }

      return { ...state, data: newArr }
    }
    case 'ARTICLES_MOVE_LEFT': {
      const { dragArticleId, dropArticleId, articleData } = action.payload
      // put togather all rows and artciels
      const newArr = []

      const copyState = state.data.map((e) => e)

      for (let i = 0; i < copyState.length; i++) {
        const row = copyState[i]
        let newRowData = []

        for (let j = 0; j < row.data.length; j++) {
          if (row.data[j].id === dropArticleId) {
            newRowData.push(articleData)
          }

          if (row.data[j].id !== dragArticleId) {
            newRowData.push(row.data[j])
          }
        }

        row.data = newRowData
        newArr.push(row)
      }

      return { ...state, data: newArr }
    }
    case 'ARTICLES_MOVE_RIGHT': {
      const { dragArticleId, dropArticleId, articleData } = action.payload
      // put togather all rows and artciels
      const newArr = []

      const copyState = state.data.map((e) => e)

      for (let i = 0; i < copyState.length; i++) {
        const row = copyState[i]
        let newRowData = []

        for (let j = 0; j < row.data.length; j++) {
          if (row.data[j].id !== dragArticleId) {
            newRowData.push(row.data[j])
          }
          if (row.data[j].id === dropArticleId) {
            newRowData.push(articleData)
          }
        }

        row.data = newRowData
        newArr.push(row)
      }

      return { ...state, data: newArr }
    }
    case 'ARTICLES_REPLACE': {
      const { dragArticleId, hoverArticleId, articleData } = action.payload
      // put togather all rows and artciels
      const newArr = []

      let dragData = null

      const copyData = state.data.map((e) => e)
      // delete drag one
      for (let i = 0; i < copyData.length; i++) {
        // row
        let row = copyData[i]
        if (dragData == null) {
          dragData = row.data.find((data) => {
            return data.id === dragArticleId
          })
        }

        const newRowData = row.data.filter((data) => data.id !== dragArticleId)
        row.data = newRowData
        newArr.push(row)
      }

      const newArr2 = []

      // insert drag one into hovered one
      for (let i = 0; i < newArr.length; i++) {
        let row = newArr[i]
        const newRowData = row.data.map((data) => {
          if (data.id === hoverArticleId) {
            return articleData
          }
          return data
        })
        row.data = newRowData
        newArr2.push(row)
      }

      return { ...state, data: newArr2 }
    }
    default:
      return state
  }
}
