const transformArticleData = ({ article, lang }) => {
  const {
    isLiked = false,
    isBookMarked,
    item_id,
    ui_columns,
    categories_ar,
    publishedDate,
    categories_en,
    mainImageUrl,
    mainVideoUrl,
    updatedAt,
    createdAt,
    slug,
    title_ar,
    title_en,
    authors = [],
    item_type,
    item_region,
    mainImage,
    mainVideo,
    showTitle,
    body_html_en,
    body_html_ar,
    byline,
    contentMetaData,
    seo_title = {},
    seo_description = {},
    templateNumber = 1,
    content_ar = '',
    keywords_ar = [],
    trimTitle = false,
    cardSummary = '',
    shortUrl = null,
    sub_articles = [],
    article_type,
    subLiveShareUrls = [],
    subLiveIds = [],
    views = {},
    tags = [],
    categories = []
  } = article

  let thumbnailUrl = ''

  if (contentMetaData) {
    for (let key of Object.keys(contentMetaData)) {
      if (contentMetaData[key].thumbnail_url) {
        thumbnailUrl = contentMetaData[key].thumbnail_url
      }
    }
  }

  return {
    id: item_id,
    uiColumns: parseInt(ui_columns, 10),
    isBookMarked,
    isLiked: isLiked || false,
    categories: lang === 'en' ? categories_en : categories_ar,
    articleCategories: categories,
    mainImageUrl,
    mainImage: {
      ...mainImage,
      title: mainImage ? mainImage[lang === 'en' ? 'titleEn' : 'titleAr'] : ''
    },
    mainVideoUrl,
    mainVideo,
    thumbnailUrl,
    showTitle,
    slug: `${slug}/`,
    title: lang === 'en' ? title_en : title_ar,
    content: lang === 'en' ? body_html_en : body_html_ar,
    byline: byline && byline[lang] ? byline[lang] : '',
    updatedAt,
    createdAt,
    publishedAt: publishedDate,
    authors: authors
      ? authors.map((author) => ({
          name: author.name ? author.name[lang] : '',
          avatar: author.avatar ? author.avatar[lang] : '',
          email: author.email ? author.email[lang] : '',
          id: author.id ? author.id : null
        }))
      : [],
    type: item_type,
    region: item_region,
    autoPlayGridVideo: false,
    templateNumber,
    seoTitle: seo_title && seo_title[lang] ? seo_title[lang] : '',
    seoDescription:
      seo_description && seo_description[lang] ? seo_description[lang] : '',
    keywordsAr: keywords_ar,
    lang,
    contentAr: content_ar,
    trimTitle,
    cardSummary,
    shortUrl,
    articleType: article_type,
    isLiveArticle: article_type && article_type === 'live',
    subArticles: sub_articles,
    coverageSummary: lang === 'en' ? body_html_en : body_html_ar, // for live article, content will be the summary
    subLiveShareUrls,
    subLiveIds,
    views,
    isPhotoArticle: false,
    tags:
      tags && tags.length > 0
        ? tags.map((tag) => ({
            name: tag.name_ar,
            id: tag.id
          }))
        : [],
    isShortHandArticle: article_type && article_type === 'shorthand'
  }
}

module.exports = transformArticleData
