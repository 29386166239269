import { put, takeEvery, delay } from "redux-saga/effects"

function* addNotification(action) {
  try {
    const { payload } = action

    yield put({ type: "CREATE_NEW_NOTIFICATION", payload })

    yield delay(5000)

    yield put({ type: "HIDE_NOTIFICATION", payload })
  } catch (e) {
    yield put({ type: "NEW_NOTIFICATION_FAILED", message: e.message })
  }
}

function* eventSaga() {
  yield takeEvery("NEW_NOTIFICATION", addNotification)
}

export default eventSaga
