export default function voteConfig(
  state = {
    fetching: false,
    fetched: false,
    error: false,
    data: []
  },
  action
) {
  switch (action.type) {
    case 'VOTE_MEGAPHONE_REQUESTED':
      return { ...state, fetching: true }
    case 'VOTE_MEGAPHONE_SUCCESS': {
      let items = []

      if (action.data && action.data.data.length) {
        items = action.data.data
      }

      return {
        ...state,
        fetching: false,
        fetched: true,
        data: items
      }
    }
    case 'VOTE_MEGAPHONE_ERROR':
      return { ...state, error: true }
    default:
      return state
  }
}
