class ToastrHelper {
  success(msg) {
    if (typeof window !== "undefined") {
      window.toastr.options.preventDuplicates = true
      window.toastr.options.closeDuration = 30
      window.toastr.options.timeOut = 3000
      window.toastr.options.showMethod = "fadeIn"
      window.toastr.options.hideMethod = "fadeOut"
      window.toastr.options.closeButton = true
      window.toastr.success(msg)
    }
  }
}
const toastrHelper = new ToastrHelper()

export default toastrHelper
