export default function shorthandArticles(
  state = {
    page: 0,
    isFetching: false,
    hasMore: true,
    isError: false,
    message: null,
    data: []
  },
  action
) {
  switch (action.type) {
    case 'SHORTHAND_ARTICLES_REQUEST_START':
      return { ...state, isFetching: true }
    case 'SHORTHAND_ARTICLES_SUCCESS':
      return {
        ...state,
        isFetching: false,
        hasMore: !!action.data.length,
        page: state.page + 1,
        data: [...state.data, ...action.data]
      }
    case 'SHORTHAND_ARTICLES_ERROR':
      return {
        ...state,
        isError: true,
        isFetching: false,
        message: action.message
      }
    case 'SHORTHAND_ARTICLES_RESET_START':
      return {
        page: 0,
        isFetching: false,
        hasMore: true,
        isError: false,
        message: null,
        data: []
      }
    default:
      return state
  }
}
