export default gridSize => {
  return (
    {
      s: 1,
      m: 2,
      l: 4,
      xl: 4,
    }[gridSize] || 4
  )
}
