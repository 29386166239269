const transformSportsArticle = ({ article }) => {
  const {
    updatedAt = '',
    title = '',
    slug = '',
    byline = '',
    content = '',
    contentText = '',
    publishedAt = '',
    categories = [],
    authors = [],
    tags = [],
    image = {},
    video = {},
    seo = {},
    trimTitle = false,
    cardSummary = '',
    shortUrl = null,
    sub_articles = [],
    subLiveShareUrls = [],
    subLiveIds = [],
    views = {},
    itemId = '',
    contentWithSchema = '',
    type = 'news',
    opta = {}
  } = article

  return {
    id: itemId,
    uiColumns: 0,
    isBookMarked: false,
    isLiked: false,
    categories,
    mainImageUrl: image && image.url ? image.url : null,
    mainImageUrlOption: image && image.options ? image.options : {},
    mainImage: { ...image, title: image && image.name ? image.name : '' },
    mainVideoUrl: video && video.url ? video.url : null,
    mainVideo: video,
    thumbnailUrl: '',
    showTitle: true,
    slug: `${slug}/`,
    title,
    content:
      contentWithSchema && contentWithSchema !== ''
        ? contentWithSchema
        : content,
    byline,
    updatedAt,
    createdAt: updatedAt,
    publishedAt,
    authors: authors
      ? authors.map((author) => ({
          name: author.name ? author.name : '',
          avatar: '',
          email: '',
          id: null,
          slug: author.slug ? author.slug : ''
        }))
      : [],
    type: 'article',
    articleType: type,
    region: [],
    autoPlayGridVideo: false,
    templateNumber: 1,
    seoTitle: seo && seo.title ? seo.title : '',
    seoDescription: seo && seo.description ? seo.description : '',
    keywordsAr: seo && seo.keywords ? seo.keywords : [],
    lang: 'ar',
    contentAr: contentText,
    trimTitle,
    cardSummary,
    shortUrl,
    isLiveArticle: false,
    subArticles: sub_articles,
    coverageSummary: content,
    subLiveShareUrls,
    subLiveIds,
    views,
    isPhotoArticle: false,
    tags:
      tags && tags.length > 0
        ? tags.map((tag) => ({
            name: tag.name,
            id: tag.slug,
            slug: tag.slug
          }))
        : [],
    image,
    isShortHandArticle: false,
    opta
  }
}

module.exports = transformSportsArticle
