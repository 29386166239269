class SiteState {
  constructor() {
    this.categoryData = []
    this.existingCatData = []
    this.isWindow = typeof window !== 'undefined'

    if (this.isWindow) {
      window.categoriesName = []
    }
  }

  getState() {
    if (this.isWindow) {
      this.categoryData = localStorage.getItem('existingCatData') || null
      this.existingCatData = this.categoryData
        ? JSON.parse(this.categoryData)
        : []
      window.categoriesName = this.existingCatData
    }
    return this.existingCatData
  }

  setState(names) {
    if (this.isWindow) {
      localStorage.setItem('existingCatData', JSON.stringify(names))
      window.categoriesName = names
    }
  }
}

const siteState = new SiteState()

export default siteState
