// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-reset-js": () => import("./../../../src/pages/account/reset.js" /* webpackChunkName: "component---src-pages-account-reset-js" */),
  "component---src-pages-account-verify-js": () => import("./../../../src/pages/account/verify.js" /* webpackChunkName: "component---src-pages-account-verify-js" */),
  "component---src-pages-ar-about-js": () => import("./../../../src/pages/ar/about.js" /* webpackChunkName: "component---src-pages-ar-about-js" */),
  "component---src-pages-ar-privacy-policy-js": () => import("./../../../src/pages/ar/privacy-policy.js" /* webpackChunkName: "component---src-pages-ar-privacy-policy-js" */),
  "component---src-pages-ar-search-js": () => import("./../../../src/pages/ar/search.js" /* webpackChunkName: "component---src-pages-ar-search-js" */),
  "component---src-pages-ar-terms-conditions-js": () => import("./../../../src/pages/ar/terms-conditions.js" /* webpackChunkName: "component---src-pages-ar-terms-conditions-js" */),
  "component---src-pages-author-preview-js": () => import("./../../../src/pages/author-preview.js" /* webpackChunkName: "component---src-pages-author-preview-js" */),
  "component---src-pages-category-js": () => import("./../../../src/pages/category.js" /* webpackChunkName: "component---src-pages-category-js" */),
  "component---src-pages-en-js": () => import("./../../../src/pages/en.js" /* webpackChunkName: "component---src-pages-en-js" */),
  "component---src-pages-en-search-js": () => import("./../../../src/pages/en/search.js" /* webpackChunkName: "component---src-pages-en-search-js" */),
  "component---src-pages-flowcodeprivacy-js": () => import("./../../../src/pages/flowcodeprivacy.js" /* webpackChunkName: "component---src-pages-flowcodeprivacy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-latest-news-js": () => import("./../../../src/pages/latest-news.js" /* webpackChunkName: "component---src-pages-latest-news-js" */),
  "component---src-pages-live-js": () => import("./../../../src/pages/live.js" /* webpackChunkName: "component---src-pages-live-js" */),
  "component---src-pages-maintenance-js": () => import("./../../../src/pages/maintenance.js" /* webpackChunkName: "component---src-pages-maintenance-js" */),
  "component---src-pages-platforms-js": () => import("./../../../src/pages/platforms.js" /* webpackChunkName: "component---src-pages-platforms-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-rearrange-js": () => import("./../../../src/pages/rearrange.js" /* webpackChunkName: "component---src-pages-rearrange-js" */),
  "component---src-pages-specials-js": () => import("./../../../src/pages/specials.js" /* webpackChunkName: "component---src-pages-specials-js" */),
  "component---src-pages-trending-js": () => import("./../../../src/pages/trending.js" /* webpackChunkName: "component---src-pages-trending-js" */),
  "component---src-pages-tune-in-js": () => import("./../../../src/pages/tune-in.js" /* webpackChunkName: "component---src-pages-tune-in-js" */),
  "component---src-pages-usa-election-js": () => import("./../../../src/pages/usa-election.js" /* webpackChunkName: "component---src-pages-usa-election-js" */),
  "component---src-pages-vote-js": () => import("./../../../src/pages/vote.js" /* webpackChunkName: "component---src-pages-vote-js" */),
  "component---src-pages-weather-js": () => import("./../../../src/pages/weather.js" /* webpackChunkName: "component---src-pages-weather-js" */),
  "component---src-pages-فريق-الشرق-js": () => import("./../../../src/pages/فريق-الشرق.js" /* webpackChunkName: "component---src-pages-فريق-الشرق-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-latest-news-article-js": () => import("./../../../src/templates/latest-news-article.js" /* webpackChunkName: "component---src-templates-latest-news-article-js" */),
  "component---src-templates-sitemap-js": () => import("./../../../src/templates/sitemap.js" /* webpackChunkName: "component---src-templates-sitemap-js" */),
  "component---src-templates-tags-template-js": () => import("./../../../src/templates/tags-template.js" /* webpackChunkName: "component---src-templates-tags-template-js" */)
}

