export default function articles(
  state = {
    page: 0,
    fetching: false,
    hasMore: true,
    error: false,
    data: {
      articles: [],
      headlines: [],
      videos: [],
      liveTvSearch: []
    }
  },
  action
) {
  switch (action.type) {
    case 'CONTEXTUAL_SEARCH_REQUEST_START':
      return { ...state, fetching: true }
    case 'CONTEXTUAL_SEARCH_REQUEST_SUCCESS':
      const { page } = action
      const newData = Object.assign({}, state.data, {
        ...state.data,
        articles: action.data.articles
          ? state.data.articles.concat(action.data.articles)
          : state.data.articles,
        headlines: action.data.headlines
          ? state.data.headlines.concat(action.data.headlines)
          : state.data.headlines,
        videos: action.data.videos
          ? state.data.videos.concat(action.data.videos)
          : state.data.videos,
        liveTvSearch: action.data.liveTvSearch
          ? state.data.liveTvSearch.concat(action.data.liveTvSearch)
          : state.data.liveTvSearch
      })

      let updatedData = page === 0 ? action.data : newData

      // default size of the contextual search is 8, hence if the data length is less than 8, then there are no more items
      // for all filter, it doesnt matter since we wont use infinite loader
      const hasMore = action.data.articles.length < 7 ? false : true

      const newState = {
        ...state,
        fetching: false,
        hasMore,
        page: page + 1,
        data: updatedData ? updatedData : state.data
      }

      return newState
    case 'CONTEXTUAL_SEARCH_RESET_START':
      return {
        ...state,
        page: 0,
        fetching: false,
        hasMore: true,
        error: false,
        data: {
          articles: [],
          headlines: [],
          videos: [],
          liveTvSearch: []
        }
      }
    case 'CONTEXTUAL_SEARCH_REQUEST_FAILED':
      return {
        ...state,
        page: 0,
        fetching: false,
        hasMore: false,
        error: true,
        data: {
          articles: [],
          headlines: [],
          videos: [],
          liveTvSearch: []
        }
      }
    default:
      return state
  }
}
