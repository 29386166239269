import axios from 'axios'
import { call, put, takeLatest } from 'redux-saga/effects'

const getWeatherData = async () => {
  const endpoint =
    process.env.GATSBY_ENVIRONMENT === 'production'
      ? 'https://api.asharqbusiness.com/api/v1/fe/weather'
      : 'https://devapi.asharqbusiness.com/api/v1/fe/weather'
  return await axios.get(endpoint, {})
}

function getWeatherCall() {
  return getWeatherData()
}

function* getWeather() {
  try {
    yield put({ type: 'GET_WEATHER_START' })

    const response = yield call(getWeatherCall)

    yield put({ type: 'GET_NEW_WEATHER_SUCCESS', response })
  } catch (e) {
    yield put({ type: 'GET_WEATHER_FAILED', message: e.message })
  }
}

function* articleSaga() {
  yield takeLatest('GET_WEATHER', getWeather)
}

export default articleSaga
