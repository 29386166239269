export default function notifications(
  state = {
    data: [],
  },
  action
) {
  switch (action.type) {
    case "CREATE_NEW_NOTIFICATION":
      return {
        ...state,
        data: [...state.data, { text: action.payload.text, visible: true }],
      }
    case "HIDE_NOTIFICATION":
      return {
        ...state,
        data: state.data.map(notification => {
          if (notification.text === action.payload.text) {
            notification.visible = false
          }
          return notification
        }),
      }
    default:
      return state
  }
}
