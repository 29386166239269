// custom typefaces

import 'normalize.css'
import './src/styles/global.scss'
import loadableReady from '@loadable/component'
import wrapWithProvider from './wrap-with-provider'
export const wrapRootElement = wrapWithProvider

const transitionDelay = 500

export const shouldUpdateScroll = ({}) => {
  window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)

  return false
}

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`)
  }
}

export const replaceHydrateFunction = () => {
  return (element, container, callback) => {
    loadableReady(() => {
      ReactDOM.render(element, container, callback)
    })
  }
}
