import { call, put, takeEvery } from 'redux-saga/effects'
import base from './base'
import transformGrid, { preProcessColumns } from '../utils/transform-grid-data'
import getMaxItems from '../utils/getMaxItems'

function fetchArticlesCall(payload) {
  const { page, lang, refresh = false, maxItemsInRow } = payload

  return base.get('/content/recommendations', {
    page: page + 1,
    size: 16,
    lang,
    refresh,
    maxItemsInRow
  })
}

function* fetchArticles({ payload }) {
  try {
    yield put({ type: 'LIVETV_ARTICLES_REQUEST_START' })

    const { gridSize, page, lang, gridLength } = payload
    const maxItemsInRow = getMaxItems(gridSize)
    const response = yield call(fetchArticlesCall, {
      ...payload,
      maxItemsInRow
    })

    let { data } = response.data

    const articles = preProcessColumns(data, gridLength)
    data = transformGrid({
      data: articles,
      lang,
      maxItemsInRow: getMaxItems(gridSize)
    })

    if (data.length && page === 2) {
      data[0] = {
        data: data[0],
        isCategory: true,
        isLinkable: false,
        isFirst: true,
        isLast: false,
        label: 'نرشح لك',
        isRecommendation: true
      }
    }

    yield put({
      type: 'LIVETV_ARTICLES_REQUEST_SUCCESS',
      data,
      gridSize,
      page,
      lang
    })
  } catch (e) {
    console.error('Grid Error', e)
    yield put({ type: 'LIVETV_ARTICLES_REQUEST_ERROR', message: e.message })
  }
}

function* livetvArticleSaga() {
  yield takeEvery('LIVETV_ARTICLES_REQUESTED', fetchArticles)
}

export default livetvArticleSaga
