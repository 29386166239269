import { call, put, takeEvery } from 'redux-saga/effects'
import base from './base'
import transformGrid, { preProcessColumns } from '../utils/transform-grid-data'
import getMaxItems from '../utils/getMaxItems'

function fetchArticlesCall(payload) {
  const { page, lang, articleId, refresh = false } = payload

  return base.get('/content/recommendations', {
    page: page + 1,
    size: 16,
    lang,
    articleId,
    refresh
  })
}

function* fetchArticleRecommendations({ payload }) {
  try {
    yield put({ type: 'ARTICLE_RECOMMENDATIONS_REQUEST_START' })

    const response = yield call(fetchArticlesCall, payload)
    const articleList = response.data.data || []
    const { gridSize, lang, page, gridLength } = payload

    const articles = preProcessColumns(articleList, page === 0 ? 0 : gridLength)
    const data = transformGrid({
      data: articles,
      lang,
      maxItemsInRow: getMaxItems(gridSize),
      fullRow: true
    })

    if (page === 0 && data.length) {
      data[0] = {
        data: data[0],
        isCategory: true,
        isTrending: true,
        isFirst: true,
        isLast: false,
        label: 'نرشح لك',
        isRecommendation: true
      }
    }

    yield put({
      type: 'ARTICLE_RECOMMENDATIONS_REQUEST_SUCCESS',
      data,
      articleId: payload.articleId,
      gridSize
    })
  } catch (e) {
    yield put({
      type: 'ARTICLE_RECOMMENDATIONS_REQUEST_ERROR',
      message: e.message
    })
  }
}

function* resetRecommendationArticles() {
  yield put({ type: 'ARTICLE_RECOMMENDATIONS_RESET' })
}

function* articleRecommendationsSaga() {
  yield takeEvery(
    'ARTICLE_RECOMMENDATIONS_REQUESTED',
    fetchArticleRecommendations
  )
  yield takeEvery(
    'ARTICLE_RECOMMENDATIONS_RESET_REQUEST',
    resetRecommendationArticles
  )
}

export default articleRecommendationsSaga
