import { call, put, takeLatest } from 'redux-saga/effects'
import base from './base'
import transformGrid, { preProcessColumns } from '../utils/transform-grid-data'
import getMaxItems from '../utils/getMaxItems'

function searchArticlesCall(payload) {
  const { page, searchTerm, searchCategory, searchAuthor } = payload
  const params = { page: page + 1, size: 16 }

  if (searchCategory === 'trending') {
    return base.get('/content/trending', { ...params, size: 20 })
  }

  if (searchCategory) {
    params.category = searchCategory
  } else if (searchAuthor) {
    params.author = searchAuthor
  } else {
    params.q = searchTerm
  }

  return base.get('/search', params)
}

function* searchArticles({ payload }) {
  try {
    yield put({ type: 'SEARCH_REQUEST_START' })

    const response = yield call(searchArticlesCall, payload)
    const {
      gridSize,
      lang,
      searchCategory,
      page,
      ignoreLatestNews,
      gridLength
    } = payload

    let { data } = response.data
    const articles = preProcessColumns(data, gridLength)
    data = transformGrid({
      data: articles,
      lang,
      maxItemsInRow: getMaxItems(gridSize)
    })

    if (page === 0 && data.length && searchCategory && !ignoreLatestNews) {
      if (gridSize !== 's') {
        data[0].push({ latestNews: true })
      }

      data[0] = {
        data: data[0],
        isCategory: true,
        isTrending: false,
        isFirst: true,
        isLast: false,
        label: searchCategory === 'trending' ? 'الشائع' : searchCategory,
        isRecommendation: false,
        showSocial: false
      }
    }

    yield put({
      type: 'SEARCH_REQUEST_SUCCESS',
      data,
      searchCategory,
      gridSize
    })
  } catch (e) {
    yield put({ type: 'USER_FETCH_FAILED', message: e.message })
  }
}

function* resetSearch() {
  try {
    yield put({ type: 'SEARCH_RESET_START' })
  } catch (e) {}
}

function* articleSaga() {
  yield takeLatest('SEARCH_REQUESTED', searchArticles)
  yield takeLatest('SEARCH_RESET', resetSearch)
}

export default articleSaga
