import { call, put, takeEvery } from 'redux-saga/effects'
import base from './base'
import transformGrid from '../utils/transform-grid-data'

function fetchVideoCall(payload) {
  const { page } = payload

  return base.get('/vod/recommendations', {
    page: page + 1,
    size: 20
  })
}

function* fetchVideos({ payload }) {
  try {
    yield put({ type: 'VIDEOS_REQUEST_START' })

    const { page, lang } = payload
    const response = yield call(fetchVideoCall, {
      ...payload
    })

    let { data } = response.data

    // maxItemsInRow is 5 as per design for desktop
    data = transformGrid({ data, lang, maxItemsInRow: 5 })

    if (page === 0) {
      window.scrollTo(0, 0)
    }

    data.forEach((rowData, index) => {
      data[index] = {
        data: rowData,
        isCategory: true,
        isLinkable: false,
        isFirst: page === 0 && index === 0 ? true : false,
        isLast: false,
        label: 'نرشح لك',
        isRecommendation: page === 0 && index === 0 ? true : false,
        isVideoRecommendation: true
      }
    })

    yield put({
      type: 'VIDEOS_REQUEST_SUCCESS',
      data,
      page
    })

    if (page === 0) {
      yield put({
        type: 'VIDEOS_REQUESTED',
        payload: { ...payload, page: page + 1 }
      })
    }
  } catch (e) {
    console.error('Grid Error', e)
    yield put({ type: 'VIDEOS_REQUEST_ERROR', message: e.message })
  }
}

function* videosSaga() {
  yield takeEvery('VIDEOS_REQUESTED', fetchVideos)
}

export default videosSaga
