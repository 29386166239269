export default function articleRecommendations(
  state = {
    page: 0, // page should start from 2 to ignore first 2 home items rows in recommendations api
    fetching: false,
    articleId: null,
    hasMore: true,
    error: false,
    data: []
  },
  action
) {
  switch (action.type) {
    case 'LIVETV_ARTICLES_REQUEST_START':
      return { ...state, fetching: true }
    case 'LIVETV_ARTICLES_REQUEST_SUCCESS':
      const data = action.data
      return {
        ...state,
        fetching: false,
        hasMore: !!data.length,
        page: state.page + 1,
        data: action.page === 0 ? data : [...state.data, ...data],
        firstRequest: false,
        gridSize: action.gridSize,
        lang: action.lang
      }
    case 'LIVETV_ARTICLES_REQUEST_ERROR':
      return { ...state, error: true }
    default:
      return state
  }
}
