import { combineReducers } from 'redux'
import articles from './articles'
import voteConfig from './voteConfig'
import search from './search'
import events from './events'
import articleRecommendations from './articleRecommendations'
import categories from './categories'
import category from './categoryById'
import weather from './weather'
import notifications from './notifications'
import register from './register'
import { reducer as toastrReducer } from 'react-redux-toastr'
import latestNews from './latestNews'
import login from './login'
import similarArticles from './similarArticles'
import toolbar from './toolbar'
import user from './user'
import countries from './countries'
import contextualSearch from './contextual-search'
import videos from './videosPage'
import livetvRecommendations from './livetvRecommendations'
import author from './author'
import quickLinks from './quickLinks'
import banner from './banner'
import adsManager from './adManager'
import liveTvSearch from './livetvSearch'
import tags from './tags'
import tagById from './tagById'
import shorthandArticles from './shorthandArticles'

export default combineReducers({
  quickLinks,
  voteConfig,
  articles,
  search,
  events,
  articleRecommendations,
  categories,
  category,
  weather,
  notifications,
  register,
  toastr: toastrReducer,
  latestNews,
  login,
  similarArticles,
  toolbar,
  user,
  countries,
  contextualSearch,
  videos,
  livetvRecommendations,
  author,
  banner,
  adsManager,
  liveTvSearch,
  tags,
  tagById,
  shorthandArticles
})
