import { store } from '../../wrap-with-provider'
import base from '../sagas/base'
import VisitManager from './VisitManager'

let categoryName = null

function getAppState() {
  const appState = store.getState()
  const { categories = {} } = appState
  return categories
}

export const getCategoryPage = () => {
  const { pathname } = window.location
  const catName = getAppState()
  const name = (decodeURIComponent(pathname).replaceAll('/', '') || '').trim()
  let isCategoryPage = false

  if (catName && catName.categoriesName && catName.categoriesName.length) {
    isCategoryPage = catName.categoriesName.includes(name)
  } else {
    isCategoryPage = window.categoriesName
      ? window.categoriesName.includes(name)
      : false
  }

  categoryName = isCategoryPage ? name : null

  if (categoryName) {
    window.currCategory = name
  }

  return isCategoryPage
}

export const pageName = () => {
  const { search, pathname } = window.location
  const isCategoryPage = getCategoryPage(pathname)

  if (search.includes('?category=%D9%81%D9%8A%D8%AF%D9%8A%D9%88')) {
    return 'videopage'
  } else if (search.includes('?query=')) {
    return 'searchpage'
  } else if (search.includes('?author=')) {
    return 'authorpage'
  } else if (
    isCategoryPage ||
    search.includes('?category') ||
    pathname === '/trending/' ||
    pathname.includes('/category/')
  ) {
    return 'categorypage'
  } else if (pathname.includes('/tags/')) {
    return 'tagspage'
  } else if (pathname.includes('latest-news')) {
    return 'latestnewspage'
  } else if (pathname.includes('/live')) {
    return 'livetvpage'
  } else if (pathname.includes('weather')) {
    return 'weatherpage'
  } else if (pathname.includes('platforms')) {
    return 'platformspage'
  } else if (pathname.includes('sitemap')) {
    return 'sitemappage'
  } else if (pathname === '/') {
    return 'homepage'
  } else if (pathname.includes('/specials/')) {
    return 'specials'
  } else {
    return 'articlepage'
  }
}
export const itemClickData = () => {
  const page = pageName()
  const { pathname } = window.location

  if (page === 'latestnewspage') {
    const res = pathname.match(/latest-news\/(?<id>.*)\//)
    if (!!res) {
      return {
        itemType: 'headline',
        itemId: res.groups.id
      }
    }
  } else if (page === 'articlepage') {
    const itemId = pathname.substr(4, pathname.indexOf('-') - 4)
    if (itemId) {
      return {
        itemType: 'article',
        itemId
      }
    }
  }

  return null
}

export const sendEvent = (eventType, eventData) => {
  VisitManager.updateLastEvent()

  if (eventData.itemId) {
    eventData.itemId = `${eventData.itemId}`
  }

  if (eventData.sourceId) {
    eventData.sourceId = `${eventData.sourceId}`
  }
  return base
    .post(`/events`, {
      eventType,
      eventData
    })
    .then((response) => {
      // console.info("event success:", response.data)
    })
    .catch((error) => {
      console.info({ error })
    })
}

export const sendEventHomeIconClick = async (isSideBar) => {
  return base.post(`/events`, {
    eventType: 'homeIconClick',
    eventData: {
      page: pageName(),
      isSideBar
    }
  })
}

export const sendEventBackIconClick = (sourceId, sourceType, customPage) => {
  sendEvent('backIconClick', {
    page: customPage ? customPage : pageName(),
    sourceId,
    sourceType
  })
}

export const sendEventSearch = (query) => {
  sendEvent('search', {
    query,
    page: pageName()
  })
}

export const sendEventSimilarClick = (data) => {
  sendEvent('similarItemClick', {
    page: pageName(),
    ...data
  })
}

export const sendEventItemClick = (data, customPage) => {
  const { pathname } = window.location

  const page = pageName()
  if (
    data.pos &&
    (!data.pos.category || data.pos.category.includes('undefined'))
  ) {
    if (
      page === 'articlepage' ||
      (customPage && customPage === 'livearticlepage')
    ) {
      data.pos.category = 'نرشح لك'
    } else if (page === 'homepage') {
      if (data.pos.row < 3) {
        delete data.pos.category
      } else if (data.pos.row > 3) {
        data.pos.category = 'نرشح لك'
      }
    } else if (pathname === '/trending/') {
      data.pos.category = 'trending'
    } else if (page === 'categorypage') {
      const url = new URLSearchParams(window.location.search).get('category')
      data.pos.category = url || categoryName
    }
  }
  sendEvent('itemClick', {
    page: customPage ? customPage : page,
    ...data
  })
}

export const sendEventReact = (data, customPage) => {
  sendEvent('react', {
    page: customPage ? customPage : pageName(),
    ...data
  })
}

export const sendEventBookmark = (data, customPage) => {
  sendEvent('bookmark', {
    page: customPage ? customPage : pageName(),
    ...data
  })
}

export const sendEventShare = (data, customPage) => {
  sendEvent('share', {
    page: customPage ? customPage : pageName(),
    ...data
  })
}

export const sendEventReadArticle = (data, customPage) => {
  sendEvent('readArticle', {
    page: customPage ? customPage : pageName(),
    ...data
  })
}

export const sendEventVideoPause = (data, customPage) => {
  sendEvent('videoPause', {
    page: customPage ? customPage : pageName(),
    ...data
  })
}

export const sendEventVideoPlay = (data, customPage) => {
  sendEvent('videoPlay', {
    page: customPage ? customPage : pageName(),
    ...data
  })
}

export const sendEventVideoLooped = (data, customPage) => {
  sendEvent('videoLooped', {
    page: customPage ? customPage : pageName(),
    ...data
  })
}

export const sendEventVideoMute = (data, customPage) => {
  sendEvent('videoMute', {
    page: customPage ? customPage : pageName(),
    ...data
  })
}

export const sendEventVideoUnmute = (data, customPage) => {
  sendEvent('videoUnmute', {
    page: customPage ? customPage : pageName(),
    ...data
  })
}

export const sendEventVideoStop = (data, customPage) => {
  sendEvent('videoStop', {
    page: customPage ? customPage : pageName(),
    ...data
  })
}
