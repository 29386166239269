import { all, call, put, takeEvery } from 'redux-saga/effects'
import isEmpty from 'lodash/isEmpty'
import base from './base'
import transformGrid, { preProcessColumns } from '../utils/transform-grid-data'
import getMaxItems from '../utils/getMaxItems'
import { fetchShorthandArticlesCall } from './shorthandArticles'
import transformSportsArticle from '../utils/transform-sports-article-data'

function fetchArticlesCall(payload) {
  const { page, lang, refresh = false, maxItemsInRow } = payload

  return base.get('/content/recommendations', {
    page: page + 1,
    size: 16,
    lang,
    refresh,
    maxItemsInRow
  })
}

function fetchNowBusinessCall() {
  return base.get('/asharqbusiness/content', {})
}

function bookmarkArticle(payload) {
  // const {articleId} = payload;
  const { value } = payload
  const params = { itemId: value.itemId }
  if (value.bookmarkActive) {
    return base.destroy('/article/bookmark', params)
  } else {
    return base.post('/article/bookmark', params)
  }
}

function* fetchArticles({ payload }) {
  try {
    yield put({ type: 'ARTICLES_REQUEST_START' })

    let asharqNowArticles = []
    let asharqBusinessArticles = []
    let shorthandArticles = []
    let sportsArticleList = []

    const { gridSize, page, lang, gridLength, isRearrange = false } = payload
    const maxItemsInRow = getMaxItems(gridSize)
    const response = yield call(fetchArticlesCall, {
      ...payload,
      maxItemsInRow
    })

    // fetch asharq now vod articles and asharq business articles if page is 1
    if (page === 1) {
      const shorthandArticlesPayload = {
        page: 0,
        size: maxItemsInRow === 1 ? 2 : 4
      }
      try {
        // minimum limit of VOD api && asharq business data is 5 and 10, so taking only the count which we need to show in UI
        // for mobile view, its always 2 cards
        const maxDataToShow = maxItemsInRow === 1 ? 2 : maxItemsInRow
        const [
          sportsArticlesResponse,
          nowBusinessResponse,
          shorthandArticlesResponse
        ] = yield all([
          call(fetchSportsArticlesCall),
          call(fetchNowBusinessCall),
          call(fetchShorthandArticlesCall, shorthandArticlesPayload)
        ])

        if (
          nowBusinessResponse &&
          nowBusinessResponse.data &&
          nowBusinessResponse.data.data
        ) {
          const { now = [], business = {} } = nowBusinessResponse.data.data

          if (now && !isEmpty(now)) {
            asharqNowArticles = now.splice(0, maxDataToShow || 4)
          }

          if (business && !isEmpty(business) && !isEmpty(business.articles)) {
            const filteredBusinessArticles = business.articles.filter(
              (article) => article.isNewsType && !article.isAdMaterial
            )
            asharqBusinessArticles = filteredBusinessArticles.splice(
              0,
              maxDataToShow || 4
            )
          }
        }

        // shorthand articles
        if (
          shorthandArticlesResponse &&
          shorthandArticlesResponse.data &&
          shorthandArticlesResponse.data.data
        ) {
          shorthandArticles = shorthandArticlesResponse.data.data
        }

        // sports articles
        if (
          sportsArticlesResponse &&
          sportsArticlesResponse.data &&
          sportsArticlesResponse.data.data
        ) {
          sportsArticleList = sportsArticlesResponse.data.data.map((article) =>
            transformSportsArticle({ article })
          )

          if (sportsArticleList && !isEmpty(sportsArticleList)) {
            sportsArticleList = sportsArticleList.splice(0, maxDataToShow || 4)
          }
        }
      } catch (error) {
        console.log(
          'Error while fetching shorthand and asharq business/now articles',
          error
        )
      }
    }

    let { data, homeItems = [] } = response.data

    // handle first 2 rows
    if (page === 0) {
      homeItems = [{ data: homeItems }]
    }

    // handle categories
    if (page === 1) {
      homeItems = homeItems.filter(
        (item) =>
          item &&
          item.label_ar !== 'الانتخابات الأميركية' &&
          item.label_id !== 'prebuilt-3049-44d3-a730-0fc3aa8abf2e'
      )

      // Sports articles from sports.asharq.com
      if (!isEmpty(sportsArticleList)) {
        homeItems.splice(2, 0, {
          data: sportsArticleList,
          label: 'رياضة',
          isSportsArticle: true,
          isCategory: true // since we are pushing into homeItems
        })
      }

      if (!isEmpty(asharqNowArticles)) {
        homeItems.splice(5, 0, {
          data: asharqNowArticles,
          isVodArticles: true,
          isCategory: true // since we are pushing into homeItems
        })
      }

      if (!isEmpty(asharqBusinessArticles)) {
        homeItems.splice(1, 0, {
          data: asharqBusinessArticles,
          isBusinessArticles: true,
          label: 'اقتصاد الشرق مع Bloomberg',
          isCategory: true // since we are pushing into homeItems
        })
      }

      if (!isEmpty(shorthandArticles)) {
        homeItems.push({
          data: shorthandArticles,
          label_en: 'ملفات خاصة',
          label_ar: 'ملفات خاصة',
          linkTo: '/specials/'
        })
      }
    }

    homeItems = homeItems.reduce((acc, item) => {
      if (
        item.isSportsArticle ||
        item.isVodArticles ||
        item.isBusinessArticles
      ) {
        acc.push({
          ...item
        })
      } else {
        let { data: homeItemsData, label_en, label_ar, linkTo } = item

        homeItemsData = transformGrid({
          data: homeItemsData,
          lang,
          maxItemsInRow,
          fullRow: false
        })

        homeItemsData.forEach((cat, index) => {
          const isTrending = label_en === 'trending'
          const label = lang === 'en' ? label_en : label_ar
          let link = isTrending ? '/trending/' : label ? `/${label}/` : ''

          if (linkTo) {
            link = linkTo
          }

          acc.push({
            data: cat,
            label,
            isCategory: true,
            isFirst: index === 0,
            isLast: index === homeItemsData.length - 1,
            isTrending,
            isLinkable: true,
            linkTo: link,
            isVodArticles: false,
            isBusinessArticles: false
          })
        })
      }

      return acc
    }, [])

    let articles

    if (page >= 2) {
      // do not preprocess cells based on ui_columns
      articles = preProcessColumns(data, gridLength)
      // {fullRow: true} => render grid without considering ui_columns key, this will make sure 4 cell grid.
      data = transformGrid({
        data: articles,
        lang,
        maxItemsInRow,
        fullRow: true
      })
    } else {
      data = transformGrid({ data, lang, maxItemsInRow, fullRow: false })
    }

    if (data.length && page === 2) {
      data[0] = {
        data: data[0],
        isCategory: true,
        isLinkable: false,
        isFirst: true,
        isLast: false,
        label: 'نرشح لك',
        isRecommendation: true
      }
    }

    if (homeItems.length) {
      data = [...homeItems, ...data]
    }

    if (page === 0) {
      window.scrollTo(0, 0)
    }

    yield put({
      type: 'ARTICLES_REQUEST_SUCCESS',
      data,
      gridSize,
      page,
      lang
    })

    if (
      !isRearrange &&
      (page === 0 || (page === 1 && homeItems.length === 0))
    ) {
      yield put({
        type: 'ARTICLES_REQUESTED',
        payload: { ...payload, page: page + 1 }
      })
    }
  } catch (e) {
    console.error('Grid Error', e)
    yield put({ type: 'ARTICLES_REQUEST_ERROR', message: e.message })
  }
}

function* resetArticles() {
  yield put({ type: 'ARTICLES_REQUEST' })
}

function* changeBookmark(payload) {
  try {
    yield put({ type: 'BOOMARK_ARTICLE_REQUESTED' })
    const response = yield call(bookmarkArticle, payload)
    yield put({ type: 'BOOKMARK_ARTICLE_SUCCESS', response, payload })
  } catch (err) {
    yield put({ type: 'BOOKMARK_ARTICLE_ERROR' })
  }
}

function fetchSportsArticlesCall() {
  return base.getSportsArticles()
}

function* articleSaga() {
  yield takeEvery('ARTICLES_REQUESTED', fetchArticles)
  yield takeEvery('ARTICLES_RESET_REQUEST', resetArticles)
  yield takeEvery('ARTICLE_CHANGE_BOOKMARK', changeBookmark)
}

export default articleSaga
