export default function category(
  state = {
    page: 0,
    isFetching: false,
    categoryId: null,
    hasMore: true,
    isError: false,
    message: null,
    data: []
  },
  action
) {
  switch (action.type) {
    case 'CATEGORY_BY_ID_REQUEST_START':
      return { ...state, categoryId: action.id, isFetching: true }
    case 'CATEGORY_BY_ID_SUCCESS':
      return {
        ...state,
        isFetching: false,
        categoryId: action.categoryId,
        hasMore: !!action.data.length,
        page: state.page + 1,
        gridSize: action.gridSize,
        data: [...state.data, ...action.data]
      }
    case 'CATEGORY_BY_ID_ERROR':
      return {
        ...state,
        isError: true,
        isFetching: false,
        message: action.message
      }
    case 'CATEGORY_BY_ID_RESET_START':
      return {
        page: 0,
        isFetching: false,
        categoryId: null,
        hasMore: true,
        isError: false,
        message: null,
        data: []
      }
    default:
      return state
  }
}
