export default function articles(
  state = { fetching: false, data: [] },
  action
) {
  switch (action.type) {
    case "SIMILAR_ARTICLES_REQUEST_START":
      return { ...state, fetching: true }
    case "SIMILAR_ARTICLES_REQUEST_SUCCESS":
      return { ...state, fetching: false, data: action.data }
    default:
      return state
  }
}
