import { call, put, takeLatest } from 'redux-saga/effects'
import base from './base'
import transformGrid from '../utils/transform-grid-data'
import getMaxItems from '../utils/getMaxItems'

function fetchTagCall(payload) {
  const { page, id } = payload

  return base.get(`/tags/${id}`, {
    page: page + 1,
    size: 16
  })
}

function* getTagById({ payload }) {
  try {
    yield put({ type: 'TAG_BY_ID_REQUEST_START' })

    const response = yield call(fetchTagCall, payload)

    const { id, gridSize, tagName, lang, page } = payload

    let { data } = response.data

    data = transformGrid({
      data: data,
      lang,
      maxItemsInRow: getMaxItems(gridSize)
    })

    if (page === 0 && data.length) {
      if (gridSize !== 's') {
        data[0].push({ latestNews: true })
      }

      data[0] = {
        data: data[0],
        isCategory: true, // make this flag true to use the CSS rules and other logics Since tags are similiar to category pages
        isTrending: false,
        isFirst: true,
        isLast: false,
        label: tagName,
        isRecommendation: false
      }
    }

    yield put({
      type: 'TAG_BY_ID_SUCCESS',
      data,
      gridSize,
      tagId: id
    })
  } catch (e) {
    yield put({ type: 'TAG_BY_ID_ERROR', message: e.message })
  }
}

function* resetTagById() {
  try {
    yield put({ type: 'TAG_BY_ID_RESET_START' })
  } catch (e) {
    console.log('Error in resetting tag', e)
  }
}

function* tagByIdSaga() {
  yield takeLatest('FETCH_TAG_BY_ID', getTagById)
  yield takeLatest('TAG_BY_ID_RESET', resetTagById)
}

export default tagByIdSaga
