import { call, put, takeLatest, select } from 'redux-saga/effects'
import { toastr } from 'react-redux-toastr'
import base from './base'

function loginCall(payload) {
  return base.post('/login', { ...payload, authRequired: false })
}

function resetCall(payload) {
  return base.post('/forgetPassword', { ...payload, authRequired: false })
}

const getArticlesPayload = ({ articles }) => {
  const { lang, gridSize } = articles
  return { lang, gridSize, page: 0, refresh: true }
}

function* login({ payload }) {
  try {
    yield put({ type: 'LOGIN_START' })

    const response = yield call(() => loginCall(payload))
    const { success, data, message } = response.data

    if (success) {
      // G360 Event for login
      dataLayer.push({
        event: 'asEvent',
        analyticsEventLabel: 'User Login',
        eventCategory: 'User',
        eventAction: 'Login',
        eventLabel: 'User Login successful'
      })
      localStorage.setItem('token', data.token)
      localStorage.setItem('refreshToken', data.refreshToken)
      localStorage.setItem('userType', data.userType)

      yield put({ type: 'LOGIN_SUCCESS', response })
      yield put({ type: 'HIDE_LOGIN' })

      const articlesPayload = yield select(getArticlesPayload)
      yield put({ type: 'ARTICLES_REQUEST' })
      yield put({ type: 'ARTICLES_REQUESTED', payload: articlesPayload })

      toastr.success('Login successful')

      yield put({ type: 'USER_REQUEST' })
    } else {
      yield put({ type: 'LOGIN_ERROR' })
      toastr.error('Login failed', message)

      dataLayer.push({
        event: 'asEvent',
        analyticsEventLabel: 'User Login',
        eventCategory: 'User',
        eventAction: 'Login',
        eventLabel: 'User Login failed'
      })
    }
  } catch (e) {
    yield put({ type: 'LOGIN_ERROR' })
    toastr.error('Login failed', 'Something went wrong')
    dataLayer.push({
      event: 'asEvent',
      analyticsEventLabel: 'User Login',
      eventCategory: 'User',
      eventAction: 'Login',
      eventLabel: 'User Login failed'
    })
  }
}

function* resetPassword({ payload }) {
  try {
    yield put({ type: 'RESET_START' })

    const response = yield call(() => resetCall(payload))

    if (response.status === 200) {
      const { success, message } = response.data

      if (success) {
        yield put({ type: 'RESET_SUCCESS', response })
        yield put({ type: 'HIDE_LOGIN' })

        toastr.success('Email sent. Please check your inbox.')
      } else {
        yield put({ type: 'RESET_ERROR' })
        toastr.error('Reset password failed', message)
      }
    } else {
      yield put({ type: 'RESET_ERROR' })
      const { message } = response.response.data
      toastr.error('Reset password failed', message)
    }
  } catch (e) {
    yield put({ type: 'RESET_ERROR' })
    toastr.error('Reset password failed', 'Something went wrong')
  }
}

function* sagas() {
  yield takeLatest('LOGIN', login)
  yield takeLatest('RESET', resetPassword)
}

export default sagas
