export default function videos(
  state = {
    page: 0,
    fetching: false,
    hasMore: true,
    error: false,
    data: [],
    lang: ''
  },
  action
) {
  switch (action.type) {
    case 'VIDEOS_REQUEST_START':
      return { ...state, fetching: true }
    case 'VIDEOS_REQUEST_ERROR':
      return { ...state, error: true }
    case 'VIDEOS_REQUEST_SUCCESS':
      const data = action.data
      return {
        ...state,
        fetching: false,
        hasMore: !!data.length,
        page: state.page + 1,
        data: action.page === 0 ? data : [...state.data, ...data]
      }
    default:
      return state
  }
}
