import { call, put, takeLatest } from 'redux-saga/effects'
import base from './base'

function getLatestNewsCall({ page, method }) {
  return base.get('/content/headlines', { size: 30, page, method })
}

function getLatestNewsArticleCall({ id }) {
  return base.get('/content', { id })
}

function* getLatestNews({ payload = {} }) {
  const { backgroundRefresh = false, page = 1, method = 'latest' } = payload

  try {
    if (!backgroundRefresh) {
      yield put({ type: 'LATEST_NEWS_START' })
    }

    const response = yield call(getLatestNewsCall, { page, method })

    yield put({
      type: 'LATEST_NEWS_SUCCESS',
      payload: {
        data: response.data.data,
        total: response.data.total || 0,
        page
      }
    })
  } catch (e) {}
}

function* getLatestNewsArticle({ payload = {} }) {
  const { id } = payload

  try {
    yield put({ type: 'LATEST_NEWS_ARTICLE_START' })

    let response = yield call(getLatestNewsArticleCall, { id })

    yield put({
      type: 'LATEST_NEWS_ARTICLE_SUCCESS',
      payload: response.data.data[0] || {}
    })
  } catch (e) {}
}

function* updateLatestNewsVisibility({ payload = false }) {
  try {
    yield put({
      type: 'LATEST_NEWS_VISIBLITY',
      payload
    })
  } catch (e) {}
}

function* categorySaga() {
  yield takeLatest('LATEST_NEWS_REQUESTED', getLatestNews)
  yield takeLatest('LATEST_NEWS_ARTICLE_REQUESTED', getLatestNewsArticle)
  yield takeLatest('UPDATE_LATEST_NEWS_VISIBLITY', updateLatestNewsVisibility)
}

export default categorySaga
