import DateTimeService from './common'

const formatDuration = (durationValue) => {
  return DateTimeService.formatDurationWithLocale(durationValue)
}

export default ({ article, lang }) => {
  const {
    isLiked,
    isBookMarked,
    date_field,
    keywords_ar = [],
    item_type,
    juicer_id,
    seoTitle,
    thumbnail_url,
    vtt_urls,
    item_region,
    createdAt,
    video_url,
    juicer_keywords,
    slug,
    updatedAt,
    player_url,
    search_milvus_ids,
    vector_ids,
    item_id,
    seoDescription,
    description_en,
    source_language,
    vod_id,
    item_meta,
    genre_ids,
    loopy_video,
    title_ar,
    showTitle,
    keywords_en,
    title_en,
    publishedDate,
    status,
    item_positive_score,
    item_date
  } = article

  return {
    id: item_id,
    isLiked,
    isBookMarked,
    dateField: date_field,
    keywordsAr: keywords_ar,
    type: item_type,
    juicerId: juicer_id,
    seoTitle: seoTitle && seoTitle[lang] ? seoTitle[lang] : '',
    mainImageUrl: thumbnail_url,
    vttUrl: vtt_urls && vtt_urls[lang] ? vtt_urls[lang] : '',
    region: item_region,
    createdAt,
    videoUrl: video_url,
    juicerKeywords: juicer_keywords,
    slug: `${slug}/`,
    updatedAt,
    playerUrl: player_url,
    searchMilvusIds: search_milvus_ids,
    vectorIds: vector_ids,
    seoDescription:
      seoDescription && seoDescription[lang] ? seoDescription[lang] : '',
    description:
      description_en && description_en[lang] ? description_en[lang] : '',
    sourceLanguage: source_language,
    vodId: vod_id,
    itemMeta: item_meta,
    genreIds: genre_ids,
    loopyVideo: loopy_video,
    title: lang === 'en' ? title_en : title_ar,
    showTitle,
    keywordsEn: keywords_en,
    publishedAt: publishedDate,
    status,
    itemScore: item_positive_score,
    itemDate: item_date,
    templateNumber: 5,
    lang,
    duration:
      item_meta && item_meta.duration
        ? formatDuration(item_meta.duration)
        : null
  }
}
