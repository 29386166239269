import { chunk } from 'lodash'
import transformArticle from './transform-article-data'
import transformVideoData from './transform-video-data'
import articleCardReshuffle from './articleCardReshuffle'

const createColumns = (arr, maxItems, fullRow = true) => {
  let row_array = []
  let max_items = { items: [], size: maxItems }
  let extra_items = []
  let overRide = false

  if (fullRow && maxItems > 1) {
    row_array = chunk(arr, 4)
    return row_array
  }

  arr.forEach((itm, idx) => {
    if (overRide) {
      row_array.push(max_items['items'])
      max_items['items'] = extra_items
      max_items['size'] = parseInt(extra_items[0]['uiColumns'] || maxItems)
      extra_items = []
      overRide = false
    }
    let ui_column = parseInt(itm['uiColumns'] || maxItems)

    if (!fullRow && ui_column === 1) {
      row_array.push([itm])
    } else {
      if (ui_column < max_items['size']) {
        if (max_items['items'].length >= ui_column) {
          extra_items.push(itm)
          overRide = true
        } else {
          max_items['size'] = ui_column
        }
      }

      if (!overRide) {
        max_items['items'].push(itm)
        if (max_items['items'].length === max_items['size']) {
          row_array.push(max_items['items'])
          max_items = { items: [], size: maxItems }
        }
        /*
         Do Not remove
         else if (idx === arr.length - 1) {
          // If last item and row doesn't have 4 elements
          max_items['size'] = max_items['items'].length
          row_array.push(max_items['items'])
        }
        */
      }
    }
  })

  if (overRide && extra_items.length) {
    max_items.items = max_items.items.concat(extra_items)
    row_array.push(max_items.items.slice(0, maxItems))
    max_items.items = max_items.items.slice(maxItems, max_items.items.length)
  }

  if (arr.length && !row_array.length) {
    row_array.push(arr)
  }

  return row_array
}

export default ({ data, lang, maxItemsInRow = 4, fullRow = true }) => {
  const articles = data.map((article) => {
    if (article && article.item_type && article.item_type === 'video') {
      return transformVideoData({ article, lang, maxItemsInRow })
    } else if (article && article.item_type) {
      return transformArticle({ article, lang, maxItemsInRow })
    }
  })

  if (maxItemsInRow === 1 && articles.length) {
    articles[0].autoPlayGridVideo = true
  }

  return createColumns(articles, maxItemsInRow, fullRow)
}

export const preProcessColumns = (articleList, gridLength, skip = true) => {
  const normalGrid = []
  const threeCols = []
  const twoCols = []

  if (skip) {
    return articleCardReshuffle(articleList, gridLength % 2 === 1)
  }

  articleList.forEach((item) => {
    if (item.ui_columns && Number(item.ui_columns) === 3) {
      threeCols.push(item)
    } else if (item.ui_columns && Number(item.ui_columns) === 2) {
      twoCols.push(item)
    } else {
      normalGrid.push(item)
    }
  })

  const articles = articleCardReshuffle(normalGrid, gridLength % 2 === 1)
  return articles.concat(threeCols, twoCols)
}
