export default function weather(
  state = { fetching: false, error: false, data: null },
  action
) {
  switch (action.type) {
    case 'GET_WEATHER_START':
      return { ...state, fetching: true }
    case 'GET_NEW_WEATHER_SUCCESS':
      const { data: newWeatherData } = action.response.data
      return {
        ...state,
        fetching: false,
        data: newWeatherData
      }
    default:
      return state
  }
}
