import { parseISO, differenceInMinutes } from 'date-fns'
import DateTimeService from './common'
class VisitManager {
  constructor() {
    this.hasStarted = false
  }

  initWithData(visitData, sendEvent) {
    let lastEvent = localStorage.getItem('lastEvent')

    if (lastEvent === null) {
      sendEvent('visit', visitData)
    } else {
      const now = new Date()
      const time = parseISO(lastEvent)
      // only send visit, if nothing has happened for the last 30 minutes
      if (differenceInMinutes(now, time) > 30) {
        sendEvent('visit', visitData)
      }
    }

    this.hasStarted = true

    this.updateLastEvent()
  }

  updateLastEvent() {
    if (this.hasStarted) {
      localStorage.setItem('lastEvent', DateTimeService.formattedDateTime())
    }
  }
}

const visitManager = new VisitManager()

export default visitManager
