import { call, put, takeLatest } from 'redux-saga/effects'
import base from './base'
import transformGrid, { preProcessColumns } from '../utils/transform-grid-data'
import getMaxItems from '../utils/getMaxItems'

export function fetchCategoryCall(payload) {
  const { page, id, size = 16 } = payload

  return base.get(`/categories/${id}`, {
    page: page + 1,
    size
  })
}

function* getCategoryById({ payload }) {
  try {
    yield put({ type: 'CATEGORY_BY_ID_REQUEST_START' })

    const response = yield call(fetchCategoryCall, payload)
    const articleList = response.data.data || []

    const { id, gridSize, gridLength, categoryName, lang, page } = payload

    const articles = preProcessColumns(articleList, gridLength)
    let data = transformGrid({
      data: articles,
      lang,
      maxItemsInRow: getMaxItems(gridSize)
    })

    if (page === 0 && data.length) {
      if (gridSize !== 's') {
        data[0].push({ latestNews: true })
      }

      data[0] = {
        data: data[0],
        isCategory: true,
        isTrending: false,
        isFirst: true,
        isLast: false,
        label: categoryName,
        isRecommendation: false
      }
    }

    yield put({
      type: 'CATEGORY_BY_ID_SUCCESS',
      data,
      gridSize,
      categoryId: id
    })
  } catch (e) {
    yield put({ type: 'CATEGORY_BY_ID_ERROR', message: e.message })
  }
}

function* resetCategoryById() {
  try {
    yield put({ type: 'CATEGORY_BY_ID_RESET_START' })
  } catch (e) {
    console.log('Error in resetting category', e)
  }
}

function* categoryByIdSaga() {
  yield takeLatest('FETCH_CATEGORY_BY_ID', getCategoryById)
  yield takeLatest('CATEGORY_BY_ID_RESET', resetCategoryById)
}

export default categoryByIdSaga
