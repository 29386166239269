export default function articles(
  state = {
    fetching: false,
    data: [],
    currentArticle: {},
    fetchingArticle: false,
    isLatestNewsVisible: false,
    totalHeadlines: 0,
    page: 1
  },
  action
) {
  switch (action.type) {
    case 'LATEST_NEWS_START':
      return { ...state, fetching: true }
    case 'LATEST_NEWS_SUCCESS':
      return {
        ...state,
        fetching: false,
        data: action.payload.data,
        totalHeadlines: action.payload.total,
        page: action.payload.page
      }
    case 'LATEST_NEWS_ERROR':
      return { ...state, fetching: false }
    case 'LATEST_NEWS_ARTICLE_START':
      return { ...state, fetchingArticle: true }
    case 'LATEST_NEWS_ARTICLE_SUCCESS':
      return {
        ...state,
        fetchingArticle: false,
        currentArticle: action.payload
      }
    case 'LATEST_NEWS_VISIBLITY':
      return {
        ...state,
        isLatestNewsVisible: action.payload
      }
    default:
      return state
  }
}
