export default function articles(
  state = { page: 0, fetching: false, hasMore: true, error: false, data: [] },
  action
) {
  switch (action.type) {
    case 'SEARCH_REQUEST_START':
      return { ...state, fetching: true }
    case 'SEARCH_REQUEST_SUCCESS':
      return {
        ...state,
        fetching: false,
        hasMore:
          action.searchCategory === 'trending' ? false : !!action.data.length,
        page: state.page + 1,
        data: [...state.data, ...action.data],
        gridSize: action.gridSize
      }
    case 'SEARCH_RESET_START':
      return {
        ...state,
        page: 0,
        fetching: false,
        hasMore: true,
        error: false,
        data: []
      }
    default:
      return state
  }
}