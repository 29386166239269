import { call, put, takeLatest } from 'redux-saga/effects'
import { toastr } from 'react-redux-toastr'
import toastrHelper from '../utils/ToastrHelper'

import base from './base'

function getUserCall() {
  return base.get('user')
}

function updateUserCall(payload) {
  return base.put('user', payload)
}

function updateUserPasswordCall(payload) {
  return base.patch('updatePassword', payload)
}

function* getUser() {
  try {
    yield put({ type: 'USER_REQUEST_START' })

    const response = yield call(getUserCall)
    const { success, data: user, message } = response.data

    if (success) {
      localStorage.setItem('user', JSON.stringify(user))

      yield put({ type: 'USER_REQUEST_SUCCESS', payload: user })

      // reloading the page to update the new user login details in dataLayer in html.js onLoad function
      window.location.reload()

    } else {
      yield put({ type: 'USER_REQUEST_ERROR', message })
    }
  } catch (e) {
    yield put({ type: 'USER_REQUEST_ERROR', message: e.message })
  }
}

function* updateUser({ payload }) {
  try {
    yield put({ type: 'USER_REQUEST_START' })

    const response = yield call(() => updateUserCall(payload))
    const { success, data: user, message } = response.data

    if (success) {
      localStorage.setItem('user', JSON.stringify(user))
      yield put({ type: 'USER_REQUEST_SUCCESS', payload: user })
      toastrHelper.success('Profile updated')
    } else {
      yield put({ type: 'USER_REQUEST_ERROR', message })
    }
  } catch (e) {
    yield put({ type: 'USER_REQUEST_ERROR', message: e.message })
  }
}
function* updateUserPassword({ payload }) {
  try {
    yield put({ type: 'USER_REQUEST_START' })

    const response = yield call(() => updateUserPasswordCall(payload))
    const { data } = response
    const { success, message } = data ? data : response.response.data

    if (success) {
      yield put({ type: 'USER_REQUEST_SUCCESS' })
      toastr.success('Profile updated')
    } else {
      toastr.error(message)
      yield put({ type: 'USER_REQUEST_ERROR', message })
    }
  } catch (e) {
    yield put({ type: 'USER_REQUEST_ERROR', message: e.message })
  }
}

function* userSaga() {
  yield takeLatest('USER_REQUEST', getUser)
  yield takeLatest('UPDATE_USER_REQUEST', updateUser)
  yield takeLatest('UPDATE_USER_PASSWORD_REQUEST', updateUserPassword)
}

export default userSaga
